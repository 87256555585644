.select2 {
	@if $enable-rtl {
		direction: rtl;
	}
	
	&.select2-container {
		display: block;
		
		& .selection {
			& .select2-selection {
				&.select2-selection--single,
				&.select2-selection--multiple {
					border-color: $input-border-color;
					outline: 0;
					height: $input-height;
					background: $input-bg;
					
					@include display-flex();
					@include flex-align(center);
					
					& .select2-selection__rendered {
						line-height: 1;
						padding: $input-padding-y $input-padding-x;
						font-weight: $font-weight-bold;
						color: $input-color;
						position: relative;
						
						@include display-flex();
						@include flex-wrap(wrap);
						@include flex(1);
						
						@if $enable-rtl {
							padding-left: $input-padding-x;
						} @else {
							padding-right: $input-padding-x;
						}
						
						& .select2-selection__clear {
							position: absolute;
							top: 0;
							bottom: 0;
							width: $input-padding-x;
							
							@if $enable-rtl {
								left: 0;
							} @else {
								right: 0;
							}
							@include display-flex();
							@include flex-align(center);
							@include flex-justify-content(center);
						}
					}
					& .select2-selection__arrow {
						height: auto;
						width: auto;
						position: relative;
						top: 0;
						right: 0;
						padding: $input-padding-y $input-padding-x;
						
						@include display-flex();
						@include flex-align(center);
						
						& b {
							position: relative;
							top: 0;
							left: 0;
							margin: 0;
							border: none;
							color: $white;
							width: auto;
							height: auto;
							
							@include fontawesome();
							
							&:before {
								content: '\f0d7';
							}
						}
					}
				}
				&.select2-selection--multiple {
					height: auto;
					min-height: $input-height;
					
					& .select2-selection__rendered {
						padding: $input-padding-y - rem-default(1px) $input-padding-x $input-padding-y / 2 - rem-default(1px);
						
						& .select2-search--inline {
							float: none;
							min-width: rem(100px);
							
							& .select2-search__field {
								margin-top: -1px;
								margin-bottom: $input-padding-y / 2;
								font-weight: $input-font-weight;
								min-height: rem(20px);
								color: $white;
								
								@include placeholder($input-placeholder-color);
							}
						}
					}
					& .select2-selection__choice {
						border: none;
						background: rgba($black, .5);
						margin-bottom: $input-padding-y / 2;
						margin-top: 0;
						padding: rem(4px) rem(8px);
						color: $white;
						float: none;
						position: relative;
						
						@include border-radius($border-radius);
						@if $enable-rtl {
							padding-left: $input-padding-y * 3;
							margin-left: $input-padding-y / 2;
							margin-right: 0;
						} @else {
							padding-right: $input-padding-y * 3;
							margin-right: $input-padding-y / 2;
						}
						
						& .select2-selection__choice__remove {
							color: $gray-500;
							font-size: $font-size-lg;
							margin: 0;
							float: none;
							position: absolute;
							top: 0;
							bottom: 0;
							width: $input-padding-y * 3;
							
							@include display-flex();
							@include flex-align(center);
							@include flex-justify-content(center);
							@if $enable-rtl {
								left: 0;
							} @else {
								right: 0;
							}
						}
					}
				}
			}
		}
		
		&.select2-container--above {
			&.select2-container--open {
				& .selection {
					& .select2-selection {
						&.select2-selection--single {
							& .select2-selection__arrow {
								& b {
									&:before {
										content: '\f0d8';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.select2-container {
	& .select2-dropdown {
		border: none;
		
		@include box-shadow($box-shadow);
		
		& .select2-search--dropdown {
			padding: $nav-link-padding-x / 2;
			
			& input {
				border-color: $gray-300;
				height: $input-height;
				padding: $input-padding-y $input-padding-x;
				outline: none;
				color: $gray-800;
				
				@include border-radius($border-radius);
				@include transition(all .2s linear);
				
				&:focus {
					border-color: $input-focus-border-color;
					
					@include box-shadow($input-focus-box-shadow);
				}
			}
		}
		& .select2-results {
			& .select2-results__options {
				& .select2-results__option {
					font-weight: $font-weight-bold;
					color: $gray-800;
					
					& .select2-results__group {
						padding: $nav-link-padding-y $nav-link-padding-x / 2 $nav-link-padding-y / 2;
						color: $gray-800;
						font-size: $font-size-sm;
					}
					& .select2-results__option {
						padding: $nav-link-padding-y $nav-link-padding-x;
					}
					&.select2-results__option--highlighted {
						background: $gray-200;
						color: $gray-800;
					}
					&.select2-results__message {
						font-weight: $font-weight-bold;
						font-size: $font-size-base;
						color: $gray-600;
						padding: $nav-link-padding-y $nav-link-padding-x / 2;
					}
					&[aria-selected=true] {
						background: $form-component-active-bg;
						color: $white;
					}
				}
			}
		}
		
		&.select2-dropdown--above {
			@include box-shadow($box-shadow);
		}
	}
}
