@import url(https://fonts.googleapis.com/css?family=Poppins);
.showbox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.title {
    position: absolute;
    width: 100%;
    margin: 10px 0 0 0;
    text-align: center;
}
.title .heading {
    font-size: 32px;
    margin: 0;
}
.credit {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    margin: 0 0 10px 0;
}
.div-icons {
    position: absolute;
    display: block;
    width: 620px;
    text-align: center;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.icon {
    width: 75px;
    height: 75px;
}

.iconSm {
    width: 50px;
    height: 50px;
}

.sunny-body path {
    fill: #edc951;
}
.sunny-long-ray {
    transform-origin: 50% 50%;
    -webkit-animation: spin 9s linear infinite;
    animation: spin 9s linear infinite;
}
.sunny-long-ray path {
    fill: #edc951;
}
.sunny-short-ray {
    transform-origin: 50% 50%;
    -webkit-animation: spin 9s linear infinite;
    animation: spin 9s linear infinite;
}
.sunny-short-ray path {
    fill: #edc951;
}
.cloud-offset path {
    fill: #222;
}
.main-cloud path {
    fill: #00a0b0;
}
.small-cloud path {
    fill: #00a0b0;
    -webkit-animation: flyby 6s linear infinite;
    animation: flyby 6s linear infinite;
}
.rain-cloud path {
    fill: #00a0b0;
    -webkit-animation: rain-cloud-color 6s ease infinite;
    animation: rain-cloud-color 6s ease infinite;
}
.rain-drops path {
    fill: #00a0b0;
    opacity: 0;
}
.rain-drops path:nth-child(1) {
    -webkit-animation: rain-drop 1.2s linear infinite;
    animation: rain-drop 1.2s linear infinite;
}
.rain-drops path:nth-child(2) {
    -webkit-animation: rain-drop 1.2s linear infinite 0.4s;
    animation: rain-drop 1.2s linear infinite 0.4s;
}
.rain-drops path:nth-child(3) {
    -webkit-animation: rain-drop 1.2s linear infinite 0.8s;
    animation: rain-drop 1.2s linear infinite 0.8s;
}
.snow-cloud path {
    fill: #ccc;
}
.snowflakes path {
    transform-origin: 50% 50%;
    fill: #ccc;
    opacity: 0;
}
.snowflakes path:nth-child(1) {
    -webkit-animation: snow-drop 1.2s linear infinite;
    animation: snow-drop 1.2s linear infinite;
}
.snowflakes path:nth-child(2) {
    -webkit-animation: snow-drop 1.2s linear infinite 0.4s;
    animation: snow-drop 1.2s linear infinite 0.4s;
}
.snowflakes path:nth-child(3) {
    -webkit-animation: snow-drop 1.2s linear infinite 0.8s;
    animation: snow-drop 1.2s linear infinite 0.8s;
}
.wind-string path {
    stroke: #ccc;
    stroke-linecap: round;
    stroke-width: 7px;
    -webkit-animation: wind-blow 3s linear infinite;
    animation: wind-blow 3s linear infinite;
}
.rainbows path {
    stroke-linecap: round;
    -webkit-animation: rainbow 4.5s linear infinite;
    animation: rainbow 4.5s linear infinite;
}
.rainbows path:nth-child(1) {
    stroke: #bd1e52;
    stroke-width: 6px;
}
.rainbows path:nth-child(2) {
    stroke: #e88024;
    stroke-width: 8px;
}
.rainbows path:nth-child(3) {
    stroke: #f8cb10;
    stroke-width: 6px;
}
.rainbows path:nth-child(4) {
    stroke: #899c3b;
    stroke-width: 14px;
}
@-webkit-keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes flyby {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    30% {
        transform: translate(39px, 0);
        opacity: 1;
    }
    70% {
        transform: translate(91px, 0);
        opacity: 1;
    }
    100% {
        transform: translate(130px, 0);
        opacity: 0;
    }
}
@keyframes flyby {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    30% {
        transform: translate(39px, 0);
        opacity: 1;
    }
    70% {
        transform: translate(91px, 0);
        opacity: 1;
    }
    100% {
        transform: translate(130px, 0);
        opacity: 0;
    }
}
@-webkit-keyframes rain-cloud-color {
    0%,
    100% {
        fill: #666;
    }
    20% {
        fill: #555;
    }
    21.5% {
        fill: #999;
    }
    25% {
        fill: #555;
    }
    27.5% {
        fill: #999;
    }
    30% {
        fill: #555;
    }
    40% {
        fill: #999;
    }
    90% {
        fill: #555;
    }
}
@keyframes rain-cloud-color {
    0%,
    100% {
        fill: #666;
    }
    20% {
        fill: #555;
    }
    21.5% {
        fill: #999;
    }
    25% {
        fill: #555;
    }
    27.5% {
        fill: #999;
    }
    30% {
        fill: #555;
    }
    40% {
        fill: #999;
    }
    90% {
        fill: #555;
    }
}
@-webkit-keyframes rain-drop {
    0% {
        transform: translate(0, -60px);
        opacity: 0;
    }
    30% {
        transform: translate(0, -36px);
        opacity: 1;
    }
    80% {
        transform: translate(0, 4px);
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px);
        opacity: 0;
    }
}
@keyframes rain-drop {
    0% {
        transform: translate(0, -60px);
        opacity: 0;
    }
    30% {
        transform: translate(0, -36px);
        opacity: 1;
    }
    80% {
        transform: translate(0, 4px);
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px);
        opacity: 0;
    }
}
@-webkit-keyframes snow-drop {
    0% {
        transform: translate(0, -60px) rotate(0);
        opacity: 0;
    }
    30% {
        transform: translate(0, -36px) rotate(108deg);
        opacity: 1;
    }
    80% {
        transform: translate(0, 4px) rotate(288deg);
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px) rotate(360deg);
        opacity: 0;
    }
}
@keyframes snow-drop {
    0% {
        transform: translate(0, -60px) rotate(0);
        opacity: 0;
    }
    30% {
        transform: translate(0, -36px) rotate(108deg);
        opacity: 1;
    }
    80% {
        transform: translate(0, 4px) rotate(288deg);
        opacity: 1;
    }
    100% {
        transform: translate(0, 20px) rotate(360deg);
        opacity: 0;
    }
}
@-webkit-keyframes wind-blow {
    0% {
        stroke-dasharray: 5 300;
        stroke-dashoffset: -200;
        opacity: 1;
    }
    50% {
        stroke-dasharray: 300 300;
        stroke-dashoffset: -100;
        opacity: 1;
    }
    90% {
        stroke-dasharray: 50 300;
        stroke-dashoffset: -20;
        opacity: 0.7;
    }
    100% {
        stroke-dasharray: 20 300;
        stroke-dashoffset: 0;
        opacity: 0.2;
    }
}
@keyframes wind-blow {
    0% {
        stroke-dasharray: 5 300;
        stroke-dashoffset: -200;
        opacity: 1;
    }
    50% {
        stroke-dasharray: 300 300;
        stroke-dashoffset: -100;
        opacity: 1;
    }
    90% {
        stroke-dasharray: 50 300;
        stroke-dashoffset: -20;
        opacity: 0.7;
    }
    100% {
        stroke-dasharray: 20 300;
        stroke-dashoffset: 0;
        opacity: 0.2;
    }
}
@-webkit-keyframes rainbow {
    0% {
        stroke-dasharray: 10 210;
        stroke-dashoffset: 0;
        opacity: 0;
    }
    30% {
        stroke-dasharray: 210 210;
        stroke-dashoffset: 0;
        opacity: 1;
    }
    70% {
        stroke-dasharray: 210 210;
        stroke-dashoffset: 0;
        opacity: 1;
    }
    100% {
        stroke-dasharray: 0 210;
        stroke-dashoffset: -210;
        opacity: 0;
    }
}
@keyframes rainbow {
    0% {
        stroke-dasharray: 10 210;
        stroke-dashoffset: 0;
        opacity: 0;
    }
    30% {
        stroke-dasharray: 210 210;
        stroke-dashoffset: 0;
        opacity: 1;
    }
    70% {
        stroke-dasharray: 210 210;
        stroke-dashoffset: 0;
        opacity: 1;
    }
    100% {
        stroke-dasharray: 0 210;
        stroke-dashoffset: -210;
        opacity: 0;
    }
}
