/* src/Components/Utils/QRCodeReader.css */

button {
    background-color: transparent;
    border: 1px solid #ffc107;
    color: #ffc107;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;
    font-family: 'Arial', sans-serif;
    text-transform: none;
}

button::before {
    font-family: 'FontAwesome';
    margin-right: 0.5rem;
}

#qr-reader__dashboard_section_csr button::before {
    content: '\f083';
}

#qr-reader {
    border: none !important;
}

#qr-reader__dashboard_section_start_scan button::before {
    content: '\f09c';
}

#qr-reader__dashboard_section_torch button::before {
    content: '\f0eb';
}

#qr-reader__dashboard_section_stop button::before {
    content: '\f04d';
}