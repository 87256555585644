.app-sidebar-minified:not(.app-without-sidebar) {
	& .app-sidebar:not(.app-sidebar-end) {
		width: $app-sidebar-minified-width;
		
		& .menu {
			padding: 0 rem(8px);
			& > .menu-item {
				& > .menu-link {
					padding: rem(10px) rem(12px);
					
					& .menu-text,
					& .menu-label,
					& .menu-badge {
						display: none;
					}
					& .menu-icon {
						margin: 0;
					}
					& .menu-icon-img {
						margin: 0;
					}
				}
				&.has-sub > .menu-submenu {
					display: none !important;
				}
			}
		}
		& .menu-profile,
		& .menu-header,
		& .menu-search {
			display: none !important;
		}
		& .menu-caret {
			@if $enable-rtl {
				margin-left: rem(-12px);
			} @else {
				margin-right: rem(-12px);
			}
			
			&:before {
				@include transform(rotate(0deg) !important);
			}
		}
		& .slimScrollDiv {
			& .slimScrollBar,
			& .slimScrollRail {
				display: none !important;
			}
		}
		& .ps__rail-x,
		& .ps__rail-y {
			display: none !important;
		}
	}
	& .app-sidebar-bg:not(.app-sidebar-end) {
		width: $app-sidebar-minified-width;
	}
	& .app-sidebar-minify-btn {
		margin-left: auto;
		margin-right: auto;
		& i {
			&:before {
				content: '\f101';
			}
		}
	}
	& .app-content {
		@include media-breakpoint-up(md) {
			@if $enable-rtl {
				margin-right: $app-sidebar-minified-width;
			} @else {
				margin-left: $app-sidebar-minified-width;
			}
		}
	}
	
	&.app-with-end-sidebar {
		& .app-content {
			@include media-breakpoint-up(md) {
				@if $enable-rtl {
					margin-left: $app-sidebar-minified-width;
					margin-right: auto;
				} @else {
					margin-right: $app-sidebar-minified-width;
					margin-left: auto;
				}
			}
		}
	}
}