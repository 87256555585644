.page-header {
	font-size: rem(24px);
	margin: 0 0 rem(15px);
	padding: 0;
	border: none;
	line-height: rem(32px);
	font-weight: 500;
	color: $white;
	
	& small {
		font-size: rem(13px);
		line-height: rem(20px);
		color: rgba($white ,.75);
	}
}