.pos-customer {
	@include media-breakpoint-up(lg) {
		height: 100%;
	
		@include display-flex();
	}
	
	& .pos-menu {
		@include display-flex();
		@include media-breakpoint-up(lg) {
			width: $pos-sidebar-menu-width;
		
			@include flex-direction-column();
		}
		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: $app-header-zindex;
			overflow: scroll;
		
			@include flex-align(center);
		}
		
		& .logo {
			padding: $spacer * 1.75;
		
			@include display-flex();
			@include flex-align(center);
			@include flex-justify-content(center);
			@include media-breakpoint-down(lg) {
				width: $pos-sidebar-menu-width;
				padding: 0 $spacer;
				height: rem(95px);
			
				@include flex(0 0 $pos-sidebar-menu-width);
			}
			& a {
				display: block;
				text-decoration: none;
				color: inherit;
			}
			& .logo-img {
				text-align: center;
				height: rem(46px);
			}
			& .logo-text {
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				letter-spacing: 1px;
				margin-top: $spacer / 3;
			}
			& img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		& .nav-container {
			@include flex(1);
			@include media-breakpoint-up(lg) {
				overflow: hidden;
				padding: 0;
			}
		}
		& .nav.nav-tabs {
			margin: 0;
			padding: 0 $spacer * 1.75;
			display: block;
		
			@include media-breakpoint-down(lg) {
				@include display-flex();
				@include flex-wrap(nowrap);
			}
	
			& .nav-item {
				@include media-breakpoint-down(lg) {
					min-width: rem(90px);
				}
				& .nav-link {
					position: relative;
					padding: $spacer / 1.75 $spacer;
					white-space: nowrap;
					text-align: center;
					border: 2px solid transparent;
					color: rgba($white, .5);
				
					@include display-flex();
					@include flex-align(center);
					@include flex-direction-column();
					@include flex-justify-content(center);
					@include border-radius($border-radius-lg);
					@include transition(color .2s linear);
			
					& .fa {
						display: block;
						margin: $spacer / 3 auto !important;
						font-size: rem(20px);
					
						@include transition(all .2s linear);
					}
					&:hover {
						color: $white;
					}
					&.active {
						color: $white;
						border-color: rgba($white, .25);
						background: none;
					}
				}
			}
		}
	}
	& .pos-content {
		@include flex(1);
		@include media-breakpoint-up(lg) {
			overflow: scroll;
		}
		@include media-breakpoint-down(lg) {
			padding-top: rem(95px);
		}
	
		& .pos-content-container {
			padding: $spacer * 1.75;
		}
		& .product-row {
			padding: 0;
			margin: $spacer * -0.75;
		
			@include display-flex();
			@include flex-wrap(wrap);
		
			& .product-container {
				padding: $spacer * 0.75;
				width: 100%;
			
				@include media-breakpoint-up(sm) {
					width: 50%;
				}
				@include media-breakpoint-up(md) {
					width: 33.33%;
				}
				@include media-breakpoint-up(lg) {
					width: 50%;
				}
				@include media-breakpoint-up(xl) {
					width: 33.33%;
				}
				@include media-breakpoint-up(xxxl) {
					width: 25%;
				}
			
				& .product {
					height: 100%;
				}
			}
		}
		& .product {
			background: $white;
			color: $gray-800;
			display: block;
			margin: 0;
			text-decoration: none;
			overflow: hidden;
			position: relative;
		
			@include border-radius($border-radius-lg);
			@include transition(all .2s linear);
			@include display-flex();
			@include flex-direction-column();
		
			&:hover {
				@include box-shadow($box-shadow);
			}
		
			& .img {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				min-height: rem(180px);
				@include transition(all .2s linear);
			}
			& .text {
				@include flex(1);
				@include display-flex();
				@include flex-direction-column();
			
				padding: $spacer * 0.75 $spacer;
			
				& .title {
					font-size: $font-size-lg;
					font-weight: $font-weight-bold;
					color: $gray-900;
					margin: 0;
				}
				& .desc {
					color: $gray-500;
					font-weight: $font-weight-bold;
					line-height: $line-height-sm;
					margin: 0 0 $spacer * 0.5;
				
					@include flex(1);
				}
				& .price {
					margin: 0;
					font-weight: $font-weight-bold;
					color: $gray-900;
					font-size: $font-size-lg;
				}
			}
			&.not-available {
				cursor: not-allowed;
			
				@include box-shadow(none !important);
			
				& .img,
				& .text {
					opacity: 0.65;
				}
				& .not-available-text {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					min-height: rem(180px);
					background: rgba($gray-700, .75);
					color: $white;
					font-size: $h2-font-size;
				
					@include display-flex();
					@include flex-align(center);
					@include flex-justify-content(center);
				}
			}
		}
	}
	& .pos-sidebar {
		color: $white;
		width: $pos-sidebar-desktop-width;
		height: 100%;
	
		@include display-flex();
		@include flex-direction-column();
		@include flex(0 0 $pos-sidebar-desktop-width);
		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: auto;
			z-index: $app-header-zindex + 1;
			display: none;
			background: $gray-700;
		}
	
		& .pos-sidebar-header {
			padding: $spacer * 0.75 $spacer * 1.75;
			background: rgba($white, .25);
			color: $white;
			position: relative;
		
			@include display-flex();
			@include flex-align(center);
			@include media-breakpoint-down(lg) {
				background: $gray-800;
			}
		
			& .back-btn {
				width: rem(50px);
				margin-top: rem(-10px);
				margin-bottom: rem(-10px);
				height: rem(50px);
				border: none;
				background: $gray-800;
				display: none;
				
				@if $enable-rtl {
					margin-right: rem(-25px);
					margin-left: rem(25px);
				} @else {
					margin-left: rem(-25px);
					margin-right: rem(25px);
				}
				@include media-breakpoint-down(lg) {
					display: block;
				}
			
				& .btn {
					color: $white;
					padding: 0;
					width: 100%;
					height: 100%;
				
					& svg {
						width: rem(20px);
					}
				}
			}
			& .icon {
				& img {
					height: rem(30px);
				}
				& + .title {
					@if $enable-rtl {
						padding-right: rem(10px);
					} @else {
						padding-left: rem(10px);
					}
				}
			}
			& .title {
				font-size: rem(14px);
				font-weight: $font-weight-bold;
			
				@include flex(1);
			}
			& .order {
				display: block;
				font-size: rem(12px);
				background: $gray-900;
				color: $white;
				padding: rem(2px) rem(4px);
			
				@include border-radius($border-radius);
				@include media-breakpoint-down(lg) {
					background: $gray-600;
				}
			}
		}
		& .pos-sidebar-nav {
			& .nav.nav-tabs {
				padding: 0;
			
				& .nav-item {
					padding: 0;
				
					& .nav-link {
						padding: rem(12px) rem(15px);
						font-size: rem(13px);
						background: rgba($gray-900, .15);
						color: rgba($white, .5);
						border: none;
					
						@include border-radius(0);
					
						&.active {
							background: rgba($white, .15);
							border-bottom-color: $white;
						color: $white;
						}
					}
				}
			}
		}
		& .pos-sidebar-body {
			padding: 0;
			margin: 0;
			overflow: scroll;
			background: rgba($white, .15);
		
			@include flex(1);
		
			& .pos-table {
				padding: 0 25px;
			
				& .pos-table-row {
					padding: 15px 0;
					margin: 0;
					position: relative;
				
					& + .pos-table-row {
						border-top: 1px solid rgba($white, .15);
					}
				
					& > div {
						padding: 0;
					
						& .input-group {
							&.qty {
								width: 85px;
								margin-top: 10px;
							
								@include flex-align(center);
							
								& .form-control {
									margin: 0;
									background: none;
									border: 0;
									padding: 0;
									text-align: center;
									line-height: 1;
									height: rem(26px);
									font-weight: $font-weight-bold;
								}
								& .btn {
									background: rgba($white, .25);
									padding: 0;
									color: rgba($white, .75);
									border: none;
									width: rem(26px);
									height: rem(26px);
								
									@include border-radius($border-radius);
									@include display-flex();
									@include flex-align(center);
									@include flex-justify-content(center);
								
									&:hover,
									&:focus {
										color: $white;
										background: rgba($white, .35);
									}
								}
							}
						}
						& .price {
							font-size: $font-size-lg;
							font-weight: $font-weight-bold;
						}
						&.total-price {
							font-size: $font-size-lg;
							font-weight: $font-weight-bold;
							
							@if $enable-rtl {
								text-align: left;
							} @else {
								text-align: right;
							}
						}
						& a {
							display: inline-block;
						}
						& .pos-product-thumb {
							@include display-flex();
		
							& .img {
								width: 60px;
								height: 60px;
								background-size: cover;
								background-position: center;
								background-repeat: no-repeat;
							
								@include border-radius($border-radius-lg);
							
								& + .info {
									@if $enable-rtl {
										padding-right: rem(15px);
									} @else {
										padding-left: rem(15px);
									}
									@include flex(1);
								}
							}
							& .info {
								@include flex(1);
							
								& .title {
									font-weight: $font-weight-bold;
									font-size: 14px;
								}
								& .single-price {
									font-weight: $font-weight-bold;
								}
							}
						}
					}
					& .pos-remove-confirmation {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: rgba($gray-800, .85);
						font-size: $font-size-lg;
						font-weight: $font-weight-bold;
						z-index: 10;
						padding: rem(15px);
					
						@include display-flex();
						@include flex-align(center);
					
						& .btn {
							min-width: rem(60px);
						}
					}
				}
			}
		}
		& .pos-sidebar-footer {
			background: rgba($white, .25);
			position: relative;
			padding: rem(10px) rem(25px);
		
			@include border-bottom-left-radius($border-radius-lg);
			@include border-bottom-right-radius($border-radius-lg);
			@include box-shadow(0 -1px rgba($gray-800, .1));
		
			& .price {
				font-weight: $font-weight-bold;
				
				@if $enable-rtl {
					text-align: left;
				} @else {
					text-align: right;
				}
			}
			& .subtotal,
			& .taxes,
			& .total {
				line-height: 28px;
				font-size: 13px;
			
				@include display-flex();
				@include flex-align(center);
				@include flex-justify-content(flex-end);
			
				& .text {
					width: 50%;
				}
				& .price {
					width: 50%;
					font-size: $font-size-lg;
				}
			}
			& .total {
				& .price {
					font-size: rem(18px);
				}
			}
			& .btn-row {
				padding: rem(10px) 0 0;
				margin: 0 rem(-25px) rem(-10px);
			
				@include display-flex();
			
				& .btn {
					display: block;
					padding: rem(10px) rem(15px);
					font-size: $font-size-lg;
					min-width: rem(100px);
					text-align: center;
					position: relative;
					border: none;
				
					@include border-radius(0);
				
					& .fa {
						display: block;
						margin: rem(8px) auto rem(5px);
					}
					&.btn-primary,
					&.btn-success {
						@include flex(1);	
					}
				}
			}
		}
	}
	&.pos-mobile-sidebar-toggled {
		& .pos-sidebar {
			@include media-breakpoint-down(lg) {
				@include display-flex();
			}
		}
	}
}

.modal.modal-pos-item {
	& .modal-dialog {
		& .modal-content {
			& .modal-body {
				& .close {
					position: absolute;
					top: rem(25px);
					z-index: 10;
					
					@if $enable-rtl {
						left: rem(25px);
					} @else {
						right: rem(25px);
					}
				}
				& .pos-product {
					@include media-breakpoint-up(lg) {
						@include display-flex();
					
						& .pos-product-img,
						& .pos-product-info {
							width: 50%;
							max-width: 50%;
							@include flex(0 0 50%);
						}
					}
					& .pos-product-img {
						& .img {
							height: 100%;
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							min-height: rem(250px);
							
							@if $enable-rtl {
								@include border-radius(0 0 $border-radius-lg $border-radius-lg);
							} @else {
								@include border-radius($border-radius-lg $border-radius-lg 0 0);
							}
							@include media-breakpoint-up(lg) {
								min-height: rem(300px);
								
								@if $enable-rtl {
									@include border-radius(0 $border-radius-lg $border-radius-lg 0);
								} @else {
									@include border-radius($border-radius-lg 0 0 $border-radius-lg);
								}
							}
						}
					}
					& .pos-product-info {
						padding: rem(25px);
						
						& .title {
							font-size: rem(22px);
						}
						& .desc {
							color: $gray-500;
							font-size: rem(13px);
							font-weight: $font-weight-bold;
						}
						& .price {
							font-weight: $font-weight-bold;
							font-size: rem(18px);
							margin: $spacer / 2 0;
						}
						& .qty {
							& .input-group {
								width: rem(95px);
							}
							& .form-control {
								font-weight: $font-weight-bold;
								font-size: $font-size-lg;
								height: rem(30px);
								color: $gray-800;
							}
							& .btn {
								background: $gray-200;
								padding: 0;
								color: $gray-500;
								border: none;
								width: rem(30px);
								height: rem(30px);
					
								@include border-radius($border-radius);
								@include display-flex();
								@include flex-align(center);
								@include flex-justify-content(center);
					
								&:hover,
								&:focus {
									color: mix($gray-900, $primary, 50%);
									background: mix($white, $primary, 60%);
								}
							}
						}
						& .option-row {
							margin-bottom: $spacer;
				
							& .option-title {
								font-size: $font-size-lg;
								font-weight: $font-weight-bold;
							}
							& .option-list {
								margin: $spacer / -3;
								padding: $spacer / 3 0;
								@include display-flex();
								@include flex-wrap(wrap);
					
								& .option {
									width: 33.33%;
									max-width: 33.33%;
									padding: $spacer / 3;
						
									@include flex(0 0 33.33%);
						
									& .option-input {
										display: none;
							
										&:checked {
											& + .option-label {
												border: 2px solid $teal;
												background: tint-color($teal, 92%);
												padding: rem(9px);
											}
										}
									}
									& .option-label {
										border: 1px solid $gray-300;
										padding: rem(10px);
										height: 100%;
							
										@include border-radius($border-radius-lg);
										@include display-flex();
										@include flex-direction-column();
										@include flex-align(center);
										@include flex-justify-content(center);
							
										& .option-text {
											font-weight: $font-weight-bold;
											display: block;
											line-height: $headings-line-height;
											text-align: center;
										}
										& .option-price {
											font-weight: $font-weight-bold;
											color: $gray-600;
											margin-top: $spacer / 3;
											line-height: $headings-line-height;
										}
									}
								}
								& label {
									padding-top: $spacer / 2;
									padding-bottom: $spacer / 2;
						
									&:before,
									&:after {
										top:  $spacer / 2;
									}
								}
							}
						}
						& .btn-row {
							margin: rem(25px) rem(-25px) rem(-25px);
							
							@include display-flex();
							
							& .btn {
								padding: rem(20px);
								position: relative;
								font-size: $font-size-lg;
								width: 50%;
								
								@include border-radius(0);
								@include display-flex();
								@include flex-align(center);
								@include flex-justify-content(center);
								
								& + .btn:before {
									content: '';
									position: absolute;
									top: -2px;
									bottom: -2px;
									
									@if $enable-rtl {
										right: -1px;
										border-right: 2px solid $white;
									} @else {
										left: -1px;
										border-left: 2 solid $white;
									}
								}
								&.btn-primary,
								&.btn-success {
									@include flex(1);
								}
							}
						}
					}
				}
			}
		}
	}
}