/* 4.18 Component - Modal Setting */



.modal h1,
.modal h2,
.modal h3,
.modal h4,
.modal h5,
.modal h6 {
	color: #ddd;
}



.modal-header {
	padding: 12px 15px;
	border-bottom-color: #ddd;
}

.modal-header .close {
	margin: -12px -15px -12px 0;
	padding: 12px 15px;
	line-height: 27px;
}

.modal-body {
	padding: 15px;
}




.modal-backdrop {
	background: rgba(0, 0, 0, 0.8);
}

.modal-backdrop.fade.in {
	opacity: 0.5;
}

.modal .btn.btn-default {
	background: #eee;
	color: #000;
}

.modal .btn.btn-default:hover,
.modal .btn.btn-default:focus {
	background: #ddd;
}

body .offcanvas-backdrop.show {
    opacity: 0.5 !important; /* Lighten the backdrop */
}


/* -------------------------------
   8.0 Predefined CSS Setting
------------------------------- */

/* 8.1 Predefined Classes */

.row {
	margin-left: -10px;
	margin-right: -10px;
}

.row>[class^="col-"] {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
}

.row.row-space-0 {
	margin-left: 0;
	margin-right: 0;
}

.row.row-space-2 {
	margin-left: -1px;
	margin-right: -1px;
}

.row.row-space-4 {
	margin-left: -2px;
	margin-right: -2px;
}

.row.row-space-6 {
	margin-left: -3px;
	margin-right: -3px;
}

.row.row-space-8 {
	margin-left: -4px;
	margin-right: -4px;
}

.row.row-space-10 {
	margin-left: -5px;
	margin-right: -5px;
}

.row.row-space-12 {
	margin-left: -6px;
	margin-right: -6px;
}

.row.row-space-14 {
	margin-left: -7px;
	margin-right: -7px;
}

.row.row-space-16 {
	margin-left: -8px;
	margin-right: -8px;
}

.row.row-space-18 {
	margin-left: -9px;
	margin-right: -9px;
}

.row.row-space-20 {
	margin-left: -10px;
	margin-right: -10px;
}

.row.row-space-22 {
	margin-left: -11px;
	margin-right: -11px;
}

.row.row-space-24 {
	margin-left: -12px;
	margin-right: -12px;
}

.row.row-space-26 {
	margin-left: -13px;
	margin-right: -13px;
}

.row.row-space-28 {
	margin-left: -14px;
	margin-right: -14px;
}

.row.row-space-30 {
	margin-left: -15px;
	margin-right: -15px;
}

.row.row-space-0>[class^="col-"] {
	padding-left: 0;
	padding-right: 0;
}

.row.row-space-2>[class^="col-"] {
	padding-left: 1px;
	padding-right: 1px;
}

.row.row-space-4>[class^="col-"] {
	padding-left: 2px;
	padding-right: 2px;
}

.row.row-space-6>[class^="col-"] {
	padding-left: 3px;
	padding-right: 3px;
}

.row.row-space-8>[class^="col-"] {
	padding-left: 4px;
	padding-right: 4px;
}

.row.row-space-10>[class^="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.row.row-space-12>[class^="col-"] {
	padding-left: 6px;
	padding-right: 6px;
}

.row.row-space-14>[class^="col-"] {
	padding-left: 7px;
	padding-right: 7px;
}

.row.row-space-16>[class^="col-"] {
	padding-left: 8px;
	padding-right: 8px;
}

.row.row-space-18>[class^="col-"] {
	padding-left: 9px;
	padding-right: 9px;
}

.row.row-space-20>[class^="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}

.row.row-space-22>[class^="col-"] {
	padding-left: 11px;
	padding-right: 11px;
}

.row.row-space-24>[class^="col-"] {
	padding-left: 12px;
	padding-right: 12px;
}

.row.row-space-26>[class^="col-"] {
	padding-left: 13px;
	padding-right: 13px;
}

.row.row-space-28>[class^="col-"] {
	padding-left: 14px;
	padding-right: 14px;
}

.row.row-space-30>[class^="col-"] {
	padding-left: 15px;
	padding-right: 15px;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-x-visible {
	overflow-x: visible !important;
}

.overflow-x-scroll {
	overflow-x: scroll !important;
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

.overflow-y-visible {
	overflow-y: visible !important;
}

.overflow-y-scroll {
	overflow-y: scroll !important;
}

.m-auto {
	margin: 0 auto !important;
}

.m-0 {
	margin: 0px !important;
}

.m-1 {
	margin: 1px !important;
}

.m-2 {
	margin: 2px !important;
}

.m-3 {
	margin: 3px !important;
}

.m-4 {
	margin: 4px !important;
}

.m-5 {
	margin: 5px !important;
}

.m-10 {
	margin: 10px !important;
}

.m-15 {
	margin: 15px !important;
}

.m-20 {
	margin: 20px !important;
}

.m-25 {
	margin: 25px !important;
}

.m-30 {
	margin: 30px !important;
}

.m-35 {
	margin: 35px !important;
}

.m-40 {
	margin: 40px !important;
}

.m-t-0 {
	margin-top: 0px !important;
}

.m-t-1 {
	margin-top: 1px !important;
}

.m-t-2 {
	margin-top: 2px !important;
}

.m-t-3 {
	margin-top: 3px !important;
}

.m-t-4 {
	margin-top: 4px !important;
}

.m-t-5 {
	margin-top: 5px !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-25 {
	margin-top: 25px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-t-35 {
	margin-top: 35px !important;
}

.m-t-40 {
	margin-top: 40px !important;
}

.m-r-0 {
	margin-right: 0px !important;
}

.m-r-1 {
	margin-right: 1px !important;
}

.m-r-2 {
	margin-right: 2px !important;
}

.m-r-3 {
	margin-right: 3px !important;
}

.m-r-4 {
	margin-right: 4px !important;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-r-15 {
	margin-right: 15px !important;
}

.m-r-20 {
	margin-right: 20px !important;
}

.m-r-25 {
	margin-right: 25px !important;
}

.m-r-30 {
	margin-right: 30px !important;
}

.m-r-35 {
	margin-right: 35px !important;
}

.m-r-40 {
	margin-right: 40px !important;
}

.m-b-0 {
	margin-bottom: 0px !important;
}

.m-b-1 {
	margin-bottom: 1px !important;
}

.m-b-2 {
	margin-bottom: 2px !important;
}

.m-b-3 {
	margin-bottom: 3px !important;
}

.m-b-4 {
	margin-bottom: 4px !important;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-25 {
	margin-bottom: 25px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.m-b-35 {
	margin-bottom: 35px !important;
}

.m-b-40 {
	margin-bottom: 40px !important;
}

.m-l-0 {
	margin-left: 0px !important;
}

.m-l-1 {
	margin-left: 1px !important;
}

.m-l-2 {
	margin-left: 2px !important;
}

.m-l-3 {
	margin-left: 3px !important;
}

.m-l-4 {
	margin-left: 4px !important;
}

.m-l-5 {
	margin-left: 5px !important;
}

.m-l-10 {
	margin-left: 10px !important;
}

.m-l-15 {
	margin-left: 15px !important;
}

.m-l-20 {
	margin-left: 20px !important;
}

.m-l-25 {
	margin-left: 25px !important;
}

.m-l-30 {
	margin-left: 30px !important;
}

.m-l-35 {
	margin-left: 35px !important;
}

.m-l-40 {
	margin-left: 40px !important;
}

.p-0 {
	padding: 0px !important;
}

.p-1 {
	padding: 1px !important;
}

.p-2 {
	padding: 2px !important;
}

.p-3 {
	padding: 3px !important;
}

.p-4 {
	padding: 4px !important;
}

.p-5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px !important;
}

.p-15 {
	padding: 15px !important;
}

.wrapper {
	padding: 15px;
}

.p-20 {
	padding: 20px !important;
}

.p-25 {
	padding: 25px !important;
}

.p-30 {
	padding: 30px !important;
}

.p-35 {
	padding: 35px !important;
}

.p-40 {
	padding: 40px !important;
}

.p-t-0 {
	padding-top: 0px !important;
}

.p-t-1 {
	padding-top: 1px !important;
}

.p-t-2 {
	padding-top: 2px !important;
}

.p-t-3 {
	padding-top: 3px !important;
}

.p-t-4 {
	padding-top: 4px !important;
}

.p-t-5 {
	padding-top: 5px !important;
}

.p-t-10 {
	padding-top: 10px !important;
}

.p-t-15 {
	padding-top: 15px !important;
}

.p-t-20 {
	padding-top: 20px !important;
}

.p-t-25 {
	padding-top: 25px !important;
}

.p-t-30 {
	padding-top: 30px !important;
}

.p-t-35 {
	padding-top: 35px !important;
}

.p-t-40 {
	padding-top: 40px !important;
}

.p-r-0 {
	padding-right: 0px !important;
}

.p-r-1 {
	padding-right: 1px !important;
}

.p-r-2 {
	padding-right: 2px !important;
}

.p-r-3 {
	padding-right: 3px !important;
}

.p-r-4 {
	padding-right: 4px !important;
}

.p-r-5 {
	padding-right: 5px !important;
}

.p-r-10 {
	padding-right: 10px !important;
}

.p-r-15 {
	padding-right: 15px !important;
}

.p-r-20 {
	padding-right: 20px !important;
}

.p-r-25 {
	padding-right: 25px !important;
}

.p-r-30 {
	padding-right: 30px !important;
}

.p-r-35 {
	padding-right: 35px !important;
}

.p-r-40 {
	padding-right: 40px !important;
}

.p-b-0 {
	padding-bottom: 0px !important;
}

.p-b-1 {
	padding-bottom: 1px !important;
}

.p-b-2 {
	padding-bottom: 2px !important;
}

.p-b-3 {
	padding-bottom: 3px !important;
}

.p-b-4 {
	padding-bottom: 4px !important;
}

.p-b-5 {
	padding-bottom: 5px !important;
}

.p-b-10 {
	padding-bottom: 10px !important;
}

.p-b-15 {
	padding-bottom: 15px !important;
}

.p-b-20 {
	padding-bottom: 20px !important;
}

.p-b-25 {
	padding-bottom: 25px !important;
}

.p-b-30 {
	padding-bottom: 30px !important;
}

.p-b-35 {
	padding-bottom: 35px !important;
}

.p-b-40 {
	padding-bottom: 40px !important;
}

.p-l-0 {
	padding-left: 0px !important;
}

.p-l-1 {
	padding-left: 1px !important;
}

.p-l-2 {
	padding-left: 2px !important;
}

.p-l-3 {
	padding-left: 3px !important;
}

.p-l-4 {
	padding-left: 4px !important;
}

.p-l-5 {
	padding-left: 5px !important;
}

.p-l-10 {
	padding-left: 10px !important;
}

.p-l-15 {
	padding-left: 15px !important;
}

.p-l-20 {
	padding-left: 20px !important;
}

.p-l-25 {
	padding-left: 25px !important;
}

.p-l-30 {
	padding-left: 30px !important;
}

.p-l-35 {
	padding-left: 35px !important;
}

.p-l-40 {
	padding-left: 40px !important;
}

.f-s-0 {
	font-size: 0px !important;
}

.f-s-1 {
	font-size: 1px !important;
}

.f-s-2 {
	font-size: 2px !important;
}

.f-s-3 {
	font-size: 3px !important;
}

.f-s-4 {
	font-size: 4px !important;
}

.f-s-5 {
	font-size: 5px !important;
}

.f-s-6 {
	font-size: 6px !important;
}

.f-s-7 {
	font-size: 7px !important;
}

.f-s-8 {
	font-size: 8px !important;
}

.f-s-9 {
	font-size: 9px !important;
}

.f-s-10 {
	font-size: 10px !important;
}

.f-s-11 {
	font-size: 11px !important;
}

.f-s-12 {
	font-size: 12px !important;
	text-align: right;
}

.f-s-13 {
	font-size: 13px !important;
}

.f-s-14 {
	font-size: 14px !important;
}

.f-s-15 {
	font-size: 15px !important;
}

.f-s-16 {
	font-size: 16px !important;
}

.f-s-17 {
	font-size: 17px !important;
}

.f-s-18 {
	font-size: 18px !important;
}

.f-s-19 {
	font-size: 19px !important;
}

.f-s-20 {
	font-size: 20px !important;
}

.l-h-0 {
	line-height: 0px !important;
}

.l-h-1 {
	line-height: 1px !important;
}

.l-h-2 {
	line-height: 2px !important;
}

.l-h-3 {
	line-height: 3px !important;
}

.l-h-4 {
	line-height: 4px !important;
}

.l-h-5 {
	line-height: 5px !important;
}

.l-h-6 {
	line-height: 6px !important;
}

.l-h-7 {
	line-height: 7px !important;
}

.l-h-8 {
	line-height: 8px !important;
}

.l-h-9 {
	line-height: 9px !important;
}

.l-h-10 {
	line-height: 10px !important;
}

.l-h-11 {
	line-height: 11px !important;
}

.l-h-12 {
	line-height: 12px !important;
}

.l-h-13 {
	line-height: 13px !important;
}

.l-h-14 {
	line-height: 14px !important;
}

.l-h-15 {
	line-height: 15px !important;
}

.l-h-16 {
	line-height: 16px !important;
}

.l-h-17 {
	line-height: 17px !important;
}

.l-h-18 {
	line-height: 18px !important;
}

.l-h-19 {
	line-height: 19px !important;
}

.l-h-20 {
	line-height: 20px !important;
}

.l-h-20 {
	line-height: 20px !important;
}

.l-h-21 {
	line-height: 21px !important;
}

.l-h-22 {
	line-height: 22px !important;
}

.l-h-23 {
	line-height: 23px !important;
}

.l-h-24 {
	line-height: 24px !important;
}

.l-h-25 {
	line-height: 25px !important;
}

.l-h-26 {
	line-height: 26px !important;
}

.l-h-27 {
	line-height: 27px !important;
}

.l-h-28 {
	line-height: 28px !important;
}

.l-h-29 {
	line-height: 29px !important;
}

.l-h-30 {
	line-height: 30px !important;
}

.t-plus-1 {
	position: relative !important;
	top: 1px !important;
}

.t-plus-2 {
	position: relative !important;
	top: 2px !important;
}

.t-plus-3 {
	position: relative !important;
	top: 3px !important;
}

.t-plus-4 {
	position: relative !important;
	top: 4px !important;
}

.t-plus-5 {
	position: relative !important;
	top: 5px !important;
}

.t-plus-6 {
	position: relative !important;
	top: 6px !important;
}

.t-plus-7 {
	position: relative !important;
	top: 7px !important;
}

.t-plus-8 {
	position: relative !important;
	top: 8px !important;
}

.t-plus-9 {
	position: relative !important;
	top: 9px !important;
}

.t-plus-10 {
	position: relative !important;
	top: 10px !important;
}

.t-minus-1 {
	position: relative !important;
	top: -1px !important;
}

.t-minus-2 {
	position: relative !important;
	top: -2px !important;
}

.t-minus-3 {
	position: relative !important;
	top: -3px !important;
}

.t-minus-4 {
	position: relative !important;
	top: -4px !important;
}

.t-minus-5 {
	position: relative !important;
	top: -5px !important;
}

.t-minus-6 {
	position: relative !important;
	top: -6px !important;
}

.t-minus-7 {
	position: relative !important;
	top: -7px !important;
}

.t-minus-8 {
	position: relative !important;
	top: -8px !important;
}

.t-minus-9 {
	position: relative !important;
	top: -9px !important;
}

.t-minus-10 {
	position: relative !important;
	top: -10px !important;
}

.l-plus-1 {
	position: relative !important;
	left: 1px !important;
}

.l-plus-2 {
	position: relative !important;
	left: 2px !important;
}

.l-plus-3 {
	position: relative !important;
	left: 3px !important;
}

.l-plus-4 {
	position: relative !important;
	left: 4px !important;
}

.l-plus-5 {
	position: relative !important;
	left: 5px !important;
}

.l-plus-6 {
	position: relative !important;
	left: 6px !important;
}

.l-plus-7 {
	position: relative !important;
	left: 7px !important;
}

.l-plus-8 {
	position: relative !important;
	left: 8px !important;
}

.l-plus-9 {
	position: relative !important;
	left: 9px !important;
}

.l-plus-10 {
	position: relative !important;
	left: 10px !important;
}

.l-minus-1 {
	position: relative !important;
	left: -1px !important;
}

.l-minus-2 {
	position: relative !important;
	left: -2px !important;
}

.l-minus-3 {
	position: relative !important;
	left: -3px !important;
}

.l-minus-4 {
	position: relative !important;
	left: -4px !important;
}

.l-minus-5 {
	position: relative !important;
	left: -5px !important;
}

.l-minus-6 {
	position: relative !important;
	left: -6px !important;
}

.l-minus-7 {
	position: relative !important;
	left: -7px !important;
}

.l-minus-8 {
	position: relative !important;
	left: -8px !important;
}

.l-minus-9 {
	position: relative !important;
	left: -9px !important;
}

.l-minus-10 {
	position: relative !important;
	left: -10px !important;
}

.r-plus-1 {
	position: relative !important;
	right: 1px !important;
}

.r-plus-2 {
	position: relative !important;
	right: 2px !important;
}

.r-plus-3 {
	position: relative !important;
	right: 3px !important;
}

.r-plus-4 {
	position: relative !important;
	right: 4px !important;
}

.r-plus-5 {
	position: relative !important;
	right: 5px !important;
}

.r-plus-6 {
	position: relative !important;
	right: 6px !important;
}

.r-plus-7 {
	position: relative !important;
	right: 7px !important;
}

.r-plus-8 {
	position: relative !important;
	right: 8px !important;
}

.r-plus-9 {
	position: relative !important;
	right: 9px !important;
}

.r-plus-10 {
	position: relative !important;
	right: 10px !important;
}

.r-minus-1 {
	position: relative !important;
	right: -1px !important;
}

.r-minus-2 {
	position: relative !important;
	right: -2px !important;
}

.r-minus-3 {
	position: relative !important;
	right: -3px !important;
}

.r-minus-4 {
	position: relative !important;
	right: -4px !important;
}

.r-minus-5 {
	position: relative !important;
	right: -5px !important;
}

.r-minus-6 {
	position: relative !important;
	right: -6px !important;
}

.r-minus-7 {
	position: relative !important;
	right: -7px !important;
}

.r-minus-8 {
	position: relative !important;
	right: -8px !important;
}

.r-minus-9 {
	position: relative !important;
	right: -9px !important;
}

.r-minus-10 {
	position: relative !important;
	right: -10px !important;
}

.b-plus-1 {
	position: relative !important;
	bottom: 1px !important;
}

.b-plus-2 {
	position: relative !important;
	bottom: 2px !important;
}

.b-plus-3 {
	position: relative !important;
	bottom: 3px !important;
}

.b-plus-4 {
	position: relative !important;
	bottom: 4px !important;
}

.b-plus-5 {
	position: relative !important;
	bottom: 5px !important;
}

.b-plus-6 {
	position: relative !important;
	bottom: 6px !important;
}

.b-plus-7 {
	position: relative !important;
	bottom: 7px !important;
}

.b-plus-8 {
	position: relative !important;
	bottom: 8px !important;
}

.b-plus-9 {
	position: relative !important;
	bottom: 9px !important;
}

.b-plus-10 {
	position: relative !important;
	bottom: 10px !important;
}

.b-minus-1 {
	position: relative !important;
	bottom: -1px !important;
}

.b-minus-2 {
	position: relative !important;
	bottom: -2px !important;
}

.b-minus-3 {
	position: relative !important;
	bottom: -3px !important;
}

.b-minus-4 {
	position: relative !important;
	bottom: -4px !important;
}

.b-minus-5 {
	position: relative !important;
	bottom: -5px !important;
}

.b-minus-6 {
	position: relative !important;
	bottom: -6px !important;
}

.b-minus-7 {
	position: relative !important;
	bottom: -7px !important;
}

.b-minus-8 {
	position: relative !important;
	bottom: -8px !important;
}

.b-minus-9 {
	position: relative !important;
	bottom: -9px !important;
}

.b-minus-10 {
	position: relative !important;
	bottom: -10px !important;
}

.hide {
	display: none !important;
}

.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

.pull-none {
	float: none !important;
}

.f-w-100 {
	font-weight: 100 !important;
}

.f-w-200 {
	font-weight: 200 !important;
}

.f-w-300 {
	font-weight: 300 !important;
}

.f-w-400 {
	font-weight: 400 !important;
}

.f-w-500 {
	font-weight: 500 !important;
}

.f-w-600,
.semi-bold {
	font-weight: 600 !important;
}

.f-w-700 {
	font-weight: 700 !important;
}

.table-valign-middle th,
.table-valign-middle td {
	vertical-align: middle !important;

}

.table-th-valign-middle th,
.table-td-valign-middle td {
	vertical-align: middle !important;
}

.table-no-border-left th:first-child,
.table-no-border-left td:first-child {
	border-left: none !important;
}

.table-no-border-bottom tr:last-child td {
	border-bottom: none !important;
}

.table-no-border-top tr:first-child td {
	border-top: none !important;
}

.table-no-border-right th:last-child,
.table-no-border-right td:last-child {
	border-right: none !important;
}

.table-valign-top th,
.table-valign-top td {
	vertical-align: top !important;
}

.table-th-valign-top th,
.table-td-valign-top td {
	vertical-align: top !important;
}

.table-valign-bottom th,
.table-valign-bottom td {
	vertical-align: bottom !important;
}

.table-th-valign-bottom th,
.table-td-valign-bottom td {
	vertical-align: bottom !important;
}

.valign-top {
	vertical-align: top !important;
}

.valign-bottom {
	vertical-align: bottom !important;
}

.valign-middle {
	vertical-align: middle !important;
}

.vertical-box {
	display: table;
	table-layout: fixed;
	border-spacing: 0;
	height: 100%;
	width: 100%;
}

.vertical-box-column {
	display: table-cell;
	vertical-align: top;
	height: 100%;
}

.vertical-box-row {
	display: table-row;
	height: 100%;
}

.vertical-box-row>.vertical-box-cell {
	position: relative;
	height: 100%;
	width: 100%;
	float: none;
}

.vertical-box-row>.vertical-box-cell>.vertical-box-inner-cell {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
}

.vertical-box.with-grid>.vertical-box-column+.vertical-box-column {
	border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.vertical-box.with-grid .vertical-box-row+.vertical-box-row>div,
.vertical-box.with-grid .vertical-box-row+.wrapper,
.vertical-box.with-grid .wrapper+.vertical-box-row>div,
.vertical-box.with-grid .wrapper+.wrapper {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.vertical-box.with-border-top {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.vertical-box.with-border-bottom {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.no-rounded-corner {
	border-radius: 0 !important;
}

.rounded-corner {
	border-radius: 50px !important;
}

.no-box-shadow {
	box-shadow: none !important;
}

.no-border {
	border: 0 !important;
}

.b-0 {
	border: 0 !important;
}

.b-t-0 {
	border-top: 0 !important;
}

.b-r-0 {
	border-right: 0 !important;
}

.b-b-0 {
	border-bottom: 0 !important;
}

.b-l-0 {
	border-left: 0 !important;
}

.b-1 {
	border: 1px solid #f2f3f4 !important;
}

.b-t-1 {
	border-top: 1px solid #f2f3f4 !important;
}

.b-r-1 {
	border-right: 1px solid #f2f3f4 !important;
}

.b-b-1 {
	border-bottom: 1px solid #f2f3f4 !important;
}

.b-l-1 {
	border-left: 1px solid #f2f3f4 !important;
}

.border-top-1 {
	border-top: 1px solid #f2f3f4 !important;
}

.border-right-1 {
	border-right: 1px solid #f2f3f4 !important;
}

.border-bottom-1 {
	border-bottom: 1px solid #f2f3f4 !important;
}

.border-left-1 {
	border-left: 1px solid #f2f3f4 !important;
}

.text-inverse,
.text-black {
	color: #000 !important;
}

.text-black-transparent-9 {
	color: rgba(0, 0, 0, 0.9) !important;
}

.text-black-transparent-8 {
	color: rgba(0, 0, 0, 0.8) !important;
}

.text-black-transparent-7 {
	color: rgba(0, 0, 0, 0.7) !important;
}

.text-black-transparent-6 {
	color: rgba(0, 0, 0, 0.6) !important;
}

.text-black-transparent-5 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-transparent-4 {
	color: rgba(0, 0, 0, 0.4) !important;
}

.text-black-transparent-3 {
	color: rgba(0, 0, 0, 0.3) !important;
}

.text-black-transparent-2 {
	color: rgba(0, 0, 0, 0.2) !important;
}

.text-black-transparent-1 {
	color: rgba(0, 0, 0, 0.1) !important;
}

.text-black-darker {
	color: #262626 !important;
}

.text-black-lighter {
	color: #666666 !important;
}

a.text-inverse:hover,
a.text-inverse:focus {
	color: #666666 !important;
}

.text-success,
.text-green {
	color: #04c142 !important;
}

.text-green-transparent-9 {
	color: rgba(4, 193, 66, 0.9) !important;
}

.text-green-transparent-8 {
	color: rgba(4, 193, 66, 0.8) !important;
}

.text-green-transparent-7 {
	color: rgba(4, 193, 66, 0.7) !important;
}

.text-green-transparent-6 {
	color: rgba(4, 193, 66, 0.6) !important;
}

.text-green-transparent-5 {
	color: rgba(4, 193, 66, 0.5) !important;
}

.text-green-transparent-4 {
	color: rgba(4, 193, 66, 0.4) !important;
}

.text-green-transparent-3 {
	color: rgba(4, 193, 66, 0.3) !important;
}

.text-green-transparent-2 {
	color: rgba(4, 193, 66, 0.2) !important;
}

.text-green-transparent-1 {
	color: rgba(4, 193, 66, 0.1) !important;
}

.text-green-darker {
	color: #039131 !important;
}

.text-green-lighter {
	color: #43d171 !important;
}

a.text-success:hover,
a.text-success:focus {
	color: #43d171 !important;
}

.text-info,
.text-aqua {
	color: #00cbff !important;
}

.text-aqua-transparent-9 {
	color: rgba(0, 203, 255, 0.9) !important;
}

.text-aqua-transparent-8 {
	color: rgba(0, 203, 255, 0.8) !important;
}

.text-aqua-transparent-7 {
	color: rgba(0, 203, 255, 0.7) !important;
}

.text-aqua-transparent-6 {
	color: rgba(0, 203, 255, 0.6) !important;
}

.text-aqua-transparent-5 {
	color: rgba(0, 203, 255, 0.5) !important;
}

.text-aqua-transparent-4 {
	color: rgba(0, 203, 255, 0.4) !important;
}

.text-aqua-transparent-3 {
	color: rgba(0, 203, 255, 0.3) !important;
}

.text-aqua-transparent-2 {
	color: rgba(0, 203, 255, 0.2) !important;
}

.text-aqua-transparent-1 {
	color: rgba(0, 203, 255, 0.1) !important;
}

.text-aqua-darker {
	color: #0098bf !important;
}

.text-aqua-lighter {
	color: #40d8ff !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #40d8ff !important;
}

.text-primary,
.text-blue {
	color: #006cff !important;
}

.text-blue-transparent-9 {
	color: rgba(52, 143, 226, 0.9) !important;
}

.text-blue-transparent-8 {
	color: rgba(52, 143, 226, 0.8) !important;
}

.text-blue-transparent-7 {
	color: rgba(52, 143, 226, 0.7) !important;
}

.text-blue-transparent-6 {
	color: rgba(52, 143, 226, 0.6) !important;
}

.text-blue-transparent-5 {
	color: rgba(52, 143, 226, 0.5) !important;
}

.text-blue-transparent-4 {
	color: rgba(52, 143, 226, 0.4) !important;
}

.text-blue-transparent-3 {
	color: rgba(52, 143, 226, 0.3) !important;
}

.text-blue-transparent-2 {
	color: rgba(52, 143, 226, 0.2) !important;
}

.text-blue-transparent-1 {
	color: rgba(52, 143, 226, 0.1) !important;
}

.text-blue-darker {
	color: #0051bf !important;
}

.text-blue-lighter {
	color: #4091ff !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #4091ff !important;
}

.text-warning,
.text-orange {
	color: #ff7b01 !important;
}

.text-orange-transparent-9 {
	color: rgba(255, 123, 1, 0.9) !important;
}

.text-orange-transparent-8 {
	color: rgba(255, 123, 1, 0.8) !important;
}

.text-orange-transparent-7 {
	color: rgba(255, 123, 1, 0.7) !important;
}

.text-orange-transparent-6 {
	color: rgba(255, 123, 1, 0.6) !important;
}

.text-orange-transparent-5 {
	color: rgba(255, 123, 1, 0.5) !important;
}

.text-orange-transparent-4 {
	color: rgba(255, 123, 1, 0.4) !important;
}

.text-orange-transparent-3 {
	color: rgba(255, 123, 1, 0.3) !important;
}

.text-orange-transparent-2 {
	color: rgba(255, 123, 1, 0.2) !important;
}

.text-orange-transparent-1 {
	color: rgba(255, 123, 1, 0.1) !important;
}

.text-orange-darker {
	color: #bf5c01 !important;
}

.text-orange-lighter {
	color: #ff9c41 !important;
}

a.text-warning:hover,
a.text-warning:focus {
	color: #ff9c41 !important;
}

.text-danger,
.text-red {
	color: #ff3502 !important;
}

.text-red-transparent-9 {
	color: rgba(255, 53, 2, 0.9) !important;
}

.text-red-transparent-8 {
	color: rgba(255, 53, 2, 0.8) !important;
}

.text-red-transparent-7 {
	color: rgba(255, 53, 2, 0.7) !important;
}

.text-red-transparent-6 {
	color: rgba(255, 53, 2, 0.6) !important;
}

.text-red-transparent-5 {
	color: rgba(255, 53, 2, 0.5) !important;
}

.text-red-transparent-4 {
	color: rgba(255, 53, 2, 0.4) !important;
}

.text-red-transparent-3 {
	color: rgba(255, 53, 2, 0.3) !important;
}

.text-red-transparent-2 {
	color: rgba(255, 53, 2, 0.2) !important;
}

.text-red-transparent-1 {
	color: rgba(255, 53, 2, 0.1) !important;
}

.text-red-darker {
	color: #bf2801 !important;
}

.text-red-lighter {
	color: #ff6841 !important;
}

a.text-danger:hover,
a.text-danger:focus {
	color: #ff6841 !important;
}

.text-white {
	color: #fff !important;
}

.text-white-transparent-9 {
	color: rgba(255, 255, 255, 0.9) !important;
}

.text-white-transparent-8 {
	color: rgba(255, 255, 255, 0.8) !important;
}

.text-white-transparent-7 {
	color: rgba(255, 255, 255, 0.7) !important;
}

.text-white-transparent-6 {
	color: rgba(255, 255, 255, 0.6) !important;
}

.text-white-transparent-5 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-transparent-4 {
	color: rgba(255, 255, 255, 0.4) !important;
}

.text-white-transparent-3 {
	color: rgba(255, 255, 255, 0.3) !important;
}

.text-white-transparent-2 {
	color: rgba(255, 255, 255, 0.2) !important;
}

.text-white-transparent-1 {
	color: rgba(255, 255, 255, 0.1) !important;
}

a.text-white:hover,
a.text-white:focus {
	color: rgba(255, 255, 255, 0.75) !important;
}

.text-silver {
	color: #f0f3f4 !important;
}

.text-silver-transparent-9 {
	color: rgba(240, 243, 244, 0.9) !important;
}

.text-silver-transparent-8 {
	color: rgba(240, 243, 244, 0.8) !important;
}

.text-silver-transparent-7 {
	color: rgba(240, 243, 244, 0.7) !important;
}

.text-silver-transparent-6 {
	color: rgba(240, 243, 244, 0.6) !important;
}

.text-silver-transparent-5 {
	color: rgba(240, 243, 244, 0.5) !important;
}

.text-silver-transparent-4 {
	color: rgba(240, 243, 244, 0.4) !important;
}

.text-silver-transparent-3 {
	color: rgba(240, 243, 244, 0.3) !important;
}

.text-silver-transparent-2 {
	color: rgba(240, 243, 244, 0.2) !important;
}

.text-silver-transparent-1 {
	color: rgba(240, 243, 244, 0.1) !important;
}

.text-silver-lighter {
	color: #f4f6f7 !important;
}

.text-silver-darker {
	color: #bbb !important;
}

a.text-silver:hover,
a.text-silver:focus {
	color: #bbb !important;
}

.text-grey,
.text-muted {
	color: #999 !important;
}

.text-grey-transparent-9 {
	color: rgba(182, 194, 201, 0.9) !important;
}

.text-grey-transparent-8 {
	color: rgba(182, 194, 201, 0.8) !important;
}

.text-grey-transparent-7 {
	color: rgba(182, 194, 201, 0.7) !important;
}

.text-grey-transparent-6 {
	color: rgba(182, 194, 201, 0.6) !important;
}

.text-grey-transparent-5 {
	color: rgba(182, 194, 201, 0.5) !important;
}

.text-grey-transparent-4 {
	color: rgba(182, 194, 201, 0.4) !important;
}

.text-grey-transparent-3 {
	color: rgba(182, 194, 201, 0.3) !important;
}

.text-grey-transparent-2 {
	color: rgba(182, 194, 201, 0.2) !important;
}

.text-grey-transparent-1 {
	color: rgba(182, 194, 201, 0.1) !important;
}

.text-grey-darker {
	color: #737373 !important;
}

.text-grey-lighter {
	color: #b3b3b3 !important;
}

a.text-grey:hover,
a.text-grey:focus {
	color: #b3b3b3 !important;
}

.text-yellow {
	color: #ffd900 !important;
}

.text-yellow-transparent-9 {
	color: rgba(255, 217, 0, 0.9) !important;
}

.text-yellow-transparent-8 {
	color: rgba(255, 217, 0, 0.8) !important;
}

.text-yellow-transparent-7 {
	color: rgba(255, 217, 0, 0.7) !important;
}

.text-yellow-transparent-6 {
	color: rgba(255, 217, 0, 0.6) !important;
}

.text-yellow-transparent-5 {
	color: rgba(255, 217, 0, 0.5) !important;
}

.text-yellow-transparent-4 {
	color: rgba(255, 217, 0, 0.4) !important;
}

.text-yellow-transparent-3 {
	color: rgba(255, 217, 0, 0.3) !important;
}

.text-yellow-transparent-2 {
	color: rgba(255, 217, 0, 0.2) !important;
}

.text-yellow-transparent-1 {
	color: rgba(255, 217, 0, 0.1) !important;
}

.text-yellow-darker {
	color: #bfa300 !important;
}

.text-yellow-lighter {
	color: #fde248 !important;
}

a.text-yellow:hover,
a.text-yellow:focus {
	color: #fde248 !important;
}

.text-purple {
	color: #9800ff !important;
}

.text-purple-transparent-9 {
	color: rgba(152, 0, 255, 0.9) !important;
}

.text-purple-transparent-8 {
	color: rgba(152, 0, 255, 0.8) !important;
}

.text-purple-transparent-7 {
	color: rgba(152, 0, 255, 0.7) !important;
}

.text-purple-transparent-6 {
	color: rgba(152, 0, 255, 0.6) !important;
}

.text-purple-transparent-5 {
	color: rgba(152, 0, 255, 0.5) !important;
}

.text-purple-transparent-4 {
	color: rgba(152, 0, 255, 0.4) !important;
}

.text-purple-transparent-3 {
	color: rgba(152, 0, 255, 0.3) !important;
}

.text-purple-transparent-2 {
	color: rgba(152, 0, 255, 0.2) !important;
}

.text-purple-transparent-1 {
	color: rgba(152, 0, 255, 0.1) !important;
}

.text-purple-darker {
	color: #7200bf !important;
}

.text-purple-lighter {
	color: #b240ff !important;
}

a.text-purple:hover,
a.text-purple:focus {
	color: #b240ff !important;
}

.text-lime {
	color: #c2e900 !important;
}

.text-lime-transparent-9 {
	color: rgba(194, 233, 0, 0.9) !important;
}

.text-lime-transparent-8 {
	color: rgba(194, 233, 0, 0.8) !important;
}

.text-lime-transparent-7 {
	color: rgba(194, 233, 0, 0.7) !important;
}

.text-lime-transparent-6 {
	color: rgba(194, 233, 0, 0.6) !important;
}

.text-lime-transparent-5 {
	color: rgba(194, 233, 0, 0.5) !important;
}

.text-lime-transparent-4 {
	color: rgba(194, 233, 0, 0.4) !important;
}

.text-lime-transparent-3 {
	color: rgba(194, 233, 0, 0.3) !important;
}

.text-lime-transparent-2 {
	color: rgba(194, 233, 0, 0.2) !important;
}

.text-lime-transparent-1 {
	color: rgba(194, 233, 0, 0.1) !important;
}

.text-lime-darker {
	color: #91af00 !important;
}

.text-lime-lighter {
	color: #d1ef40 !important;
}

a.text-lime:hover,
a.text-lime:focus {
	color: #d1ef40 !important;
}

.text-pink {
	color: #ff0081 !important;
}

.text-pink-transparent-9 {
	color: rgba(255, 0, 129, 0.9) !important;
}

.text-pink-transparent-8 {
	color: rgba(255, 0, 129, 0.8) !important;
}

.text-pink-transparent-7 {
	color: rgba(255, 0, 129, 0.7) !important;
}

.text-pink-transparent-6 {
	color: rgba(255, 0, 129, 0.6) !important;
}

.text-pink-transparent-5 {
	color: rgba(255, 0, 129, 0.5) !important;
}

.text-pink-transparent-4 {
	color: rgba(255, 0, 129, 0.4) !important;
}

.text-pink-transparent-3 {
	color: rgba(255, 0, 129, 0.3) !important;
}

.text-pink-transparent-2 {
	color: rgba(255, 0, 129, 0.2) !important;
}

.text-pink-transparent-1 {
	color: rgba(255, 0, 129, 0.1) !important;
}

.text-pink-darker {
	background-color: #b3005b !important;
}

.text-pink-lighter {
	background-color: #ff4ca7 !important;
}

a.text-pink:hover,
a.text-pink:focus {
	color: #ff4ca7 !important;
}

.text-teal {
	color: #01caca !important;
}

.text-teal-transparent-9 {
	color: rgba(1, 202, 202, 0.9) !important;
}

.text-teal-transparent-8 {
	color: rgba(1, 202, 202, 0.8) !important;
}

.text-teal-transparent-7 {
	color: rgba(1, 202, 202, 0.7) !important;
}

.text-teal-transparent-6 {
	color: rgba(1, 202, 202, 0.6) !important;
}

.text-teal-transparent-5 {
	color: rgba(1, 202, 202, 0.5) !important;
}

.text-teal-transparent-4 {
	color: rgba(1, 202, 202, 0.4) !important;
}

.text-teal-transparent-3 {
	color: rgba(1, 202, 202, 0.3) !important;
}

.text-teal-transparent-2 {
	color: rgba(1, 202, 202, 0.2) !important;
}

.text-teal-transparent-1 {
	color: rgba(1, 202, 202, 0.1) !important;
}

.text-teal-darker {
	color: #018e8e !important;
}

.text-teal-lighter {
	color: #4ddada !important;
}

a.text-teal:hover,
a.text-teal:focus {
	color: #4ddada !important;
}

.text-indigo {
	color: #5600ff !important;
}

.text-indigo-transparent-9 {
	color: rgba(86, 0, 255, 0.9) !important;
}

.text-indigo-transparent-8 {
	color: rgba(86, 0, 255, 0.8) !important;
}

.text-indigo-transparent-7 {
	color: rgba(86, 0, 255, 0.7) !important;
}

.text-indigo-transparent-6 {
	color: rgba(86, 0, 255, 0.6) !important;
}

.text-indigo-transparent-5 {
	color: rgba(86, 0, 255, 0.5) !important;
}

.text-indigo-transparent-4 {
	color: rgba(86, 0, 255, 0.4) !important;
}

.text-indigo-transparent-3 {
	color: rgba(86, 0, 255, 0.3) !important;
}

.text-indigo-transparent-2 {
	color: rgba(86, 0, 255, 0.2) !important;
}

.text-indigo-transparent-1 {
	color: rgba(86, 0, 255, 0.1) !important;
}

.text-indigo-darker {
	color: #3c00b3 !important;
}

.text-indigo-lighter {
	color: #884cff !important;
}

a.text-indigo:hover,
a.text-indigo:focus {
	color: #884cff !important;
}


/* 4.14.12 Component - Button - Lime */

.btn.btn-lime,
.btn.btn-lime.disabled,
.btn.btn-lime.disabled:hover,
.btn.btn-lime.disabled:focus,
.btn.btn-lime[disabled],
.btn.btn-lime[disabled]:hover,
.btn.btn-lime[disabled]:focus {
	color: #202700;
	background: #c2e900;
	border-color: transparent;
}

.btn-lime:hover,
.btn-lime:focus,
.btn-lime:active,
.btn-lime.active,
.btn-lime.active.focus,
.btn-lime.active:focus,
.btn-lime.active:hover,
.btn-lime:active.focus,
.btn-lime:active:focus,
.btn-lime:active:hover,
.show>.btn-lime.dropdown-toggle,
.open>.dropdown-toggle.btn-lime,
.open>.dropdown-toggle.btn-lime:hover,
.open>.dropdown-toggle.btn-lime:focus,
.btn-lime:not(:disabled):not(.disabled).active,
.btn-lime:not(:disabled):not(.disabled):active {
	background: #91af00;
	border-color: #91af00;
}

.input-group-append .btn.btn-lime,
.btn-group .btn.btn-lime:not(.active)+.btn.btn-lime,
.input-group-btn .btn.btn-lime:not(.active)+.btn.btn-lime {
	border-left-color: #91af00;
}

.input-group-prepend .btn.btn-lime {
	border-right-color: #91af00;
}


/* 4.14.13 Component - Button - Indigo */

.btn.btn-indigo,
.btn.btn-indigo.disabled,
.btn.btn-indigo.disabled:hover,
.btn.btn-indigo.disabled:focus,
.btn.btn-indigo[disabled],
.btn.btn-indigo[disabled]:hover,
.btn.btn-indigo[disabled]:focus {
	color: #fff;
	background: #5600ff;
	border-color: transparent;
}

.btn-indigo:hover,
.btn-indigo:focus,
.btn-indigo:active,
.btn-indigo.active,
.btn-indigo.active.focus,
.btn-indigo.active:focus,
.btn-indigo.active:hover,
.btn-indigo:active.focus,
.btn-indigo:active:focus,
.btn-indigo:active:hover,
.show>.btn-indigo.dropdown-toggle,
.open>.dropdown-toggle.btn-indigo,
.open>.dropdown-toggle.btn-indigo:hover,
.open>.dropdown-toggle.btn-indigo:focus,
.btn-indigo:not(:disabled):not(.disabled).active,
.btn-indigo:not(:disabled):not(.disabled):active {
	background: #3c00b3;
	border-color: #3c00b3;
}

.input-group-append .btn.btn-indigo,
.btn-group .btn.btn-indigo:not(.active)+.btn.btn-indigo,
.input-group-btn .btn.btn-indigo:not(.active)+.btn.btn-indigo {
	border-left-color: #884cff;
}

.input-group-prepend .btn.btn-indigo {
	border-right-color: #884cff;
}


/* 4.14.14 Component - Button - Pink */

.btn.btn-pink,
.btn.btn-pink.disabled,
.btn.btn-pink.disabled:hover,
.btn.btn-pink.disabled:focus,
.btn.btn-pink[disabled],
.btn.btn-pink[disabled]:hover,
.btn.btn-pink[disabled]:focus {
	color: #fff;
	background: #ff0081;
	border-color: transparent;
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.active.focus,
.btn-pink.active:focus,
.btn-pink.active:hover,
.btn-pink:active.focus,
.btn-pink:active:focus,
.btn-pink:active:hover,
.show>.btn-pink.dropdown-toggle,
.open>.dropdown-toggle.btn-pink,
.open>.dropdown-toggle.btn-pink:hover,
.open>.dropdown-toggle.btn-pink:focus,
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active {
	background: #b3005b;
	border-color: #b3005b;
}

.input-group-append .btn.btn-pink,
.btn-group .btn.btn-pink:not(.active)+.btn.btn-pink,
.input-group-btn .btn.btn-pink:not(.active)+.btn.btn-pink {
	border-left-color: #ff4ca7;
}

.input-group-prepend .btn.btn-pink {
	border-right-color: #ff4ca7;
}


/* 4.14.15 Component - Button - Green */

.btn.btn-green,
.btn.btn-green.disabled,
.btn.btn-green.disabled:hover,
.btn.btn-green.disabled:focus,
.btn.btn-green[disabled],
.btn.btn-green[disabled]:hover,
.btn.btn-green[disabled]:focus {
	color: #fff;
	background: #01caca;
	border-color: transparent;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active,
.btn-green.active.focus,
.btn-green.active:focus,
.btn-green.active:hover,
.btn-green:active.focus,
.btn-green:active:focus,
.btn-green:active:hover,
.show>.btn-green.dropdown-toggle,
.open>.dropdown-toggle.btn-green,
.open>.dropdown-toggle.btn-green:hover,
.open>.dropdown-toggle.btn-green:focus,
.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):active {
	background: #018e8e;
	border-color: #018e8e;
}

.input-group-append .btn.btn-green,
.btn-group .btn.btn-green:not(.active)+.btn.btn-green,
.input-group-btn .btn.btn-green:not(.active)+.btn.btn-green {
	border-left-color: #018e8e;
}

.input-group-prepend .btn.btn-green {
	border-right-color: #018e8e;
}



.bg-primary,
.bg-blue {
	background-color: #006cff !important;
}

.bg-blue-transparent-9 {
	background-color: rgba(0, 108, 255, 0.9) !important;
}

.bg-blue-transparent-8 {
	background-color: rgba(0, 108, 255, 0.8) !important;
}

.bg-blue-transparent-7 {
	background-color: rgba(0, 108, 255, 0.7) !important;
}

.bg-blue-transparent-6 {
	background-color: rgba(0, 108, 255, 0.6) !important;
}

.bg-blue-transparent-5 {
	background-color: rgba(0, 108, 255, 0.5) !important;
}

.bg-blue-transparent-4 {
	background-color: rgba(0, 108, 255, 0.4) !important;
}

.bg-blue-transparent-3 {
	background-color: rgba(0, 108, 255, 0.3) !important;
}

.bg-blue-transparent-2 {
	background-color: rgba(0, 108, 255, 0.2) !important;
}

.bg-blue-transparent-1 {
	background-color: rgba(0, 108, 255, 0.1) !important;
}

.bg-blue-darker {
	background-color: #0051bf !important;
}

.bg-blue-lighter {
	background-color: #4091ff !important;
}

.bg-gradient-blue {
	background: rgb(64, 145, 255) !important;
	background: -moz-linear-gradient(top, rgba(64, 145, 255, 1) 0%, rgba(0, 81, 191, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(64, 145, 255, 1) 0%, rgba(0, 81, 191, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(64, 145, 255, 1) 0%, rgba(0, 81, 191, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4091ff', endColorstr='#0051bf', GradientType=0) !important;
}

.bg-info,
.bg-aqua {
	background-color: #00cbff !important;
}

.bg-aqua-transparent-9 {
	background-color: rgba(73, 182, 214, 0.9) !important;
}

.bg-aqua-transparent-8 {
	background-color: rgba(73, 182, 214, 0.8) !important;
}

.bg-aqua-transparent-7 {
	background-color: rgba(73, 182, 214, 0.7) !important;
}

.bg-aqua-transparent-6 {
	background-color: rgba(73, 182, 214, 0.6) !important;
}

.bg-aqua-transparent-5 {
	background-color: rgba(73, 182, 214, 0.5) !important;
}

.bg-aqua-transparent-4 {
	background-color: rgba(73, 182, 214, 0.4) !important;
}

.bg-aqua-transparent-3 {
	background-color: rgba(73, 182, 214, 0.3) !important;
}

.bg-aqua-transparent-2 {
	background-color: rgba(73, 182, 214, 0.2) !important;
}

.bg-aqua-transparent-1 {
	background-color: rgba(73, 182, 214, 0.1) !important;
}

.bg-aqua-darker {
	background-color: #0098bf !important;
}

.bg-aqua-lighter {
	background-color: #6dc5de !important;
}

.bg-gradient-aqua {
	background: rgb(109, 197, 222) !important;
	background: -moz-linear-gradient(top, rgba(109, 197, 222, 1) 0%, rgba(73, 182, 214, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(109, 197, 222, 1) 0%, rgba(73, 182, 214, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(109, 197, 222, 1) 0%, rgba(73, 182, 214, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6dc5de', endColorstr='#00cbff', GradientType=0) !important;
}

.bg-success,
.bg-green {
	background-color: #276f3e !important;
}

.bg-green-transparent-9 {
	background-color: rgba(4, 193, 66, 0.9) !important;
}

.bg-green-transparent-8 {
	background-color: rgba(4, 193, 66, 0.8) !important;
}

.bg-green-transparent-7 {
	background-color: rgba(4, 193, 66, 0.7) !important;
}

.bg-green-transparent-6 {
	background-color: rgba(4, 193, 66, 0.6) !important;
}

.bg-green-transparent-5 {
	background-color: rgba(4, 193, 66, 0.5) !important;
}

.bg-green-transparent-4 {
	background-color: rgba(4, 193, 66, 0.4) !important;
}

.bg-green-transparent-3 {
	background-color: rgba(4, 193, 66, 0.3) !important;
}

.bg-green-transparent-2 {
	background-color: rgba(4, 193, 66, 0.2) !important;
}

.bg-green-transparent-1 {
	background-color: rgba(4, 193, 66, 0.1) !important;
}

.bg-green-darker {
	background-color: #039131 !important;
}

.bg-green-lighter {
	background-color: #43d171 !important;
}

.bg-gradient-green {
	background: rgb(67, 209, 113) !important;
	background: -moz-linear-gradient(top, rgba(67, 209, 113, 1) 0%, rgba(3, 145, 49, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(67, 209, 113, 1) 0%, rgba(3, 145, 49, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(67, 209, 113, 1) 0%, rgba(3, 145, 49, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#43d171', endColorstr='#039131', GradientType=0) !important;
}

.bg-danger,
.bg-red {
	background-color: #ff3502 !important;
}

.bg-red-transparent-9 {
	background-color: rgba(255, 53, 2, 0.9) !important;
}

.bg-red-transparent-8 {
	background-color: rgba(255, 53, 2, 0.8) !important;
}

.bg-red-transparent-7 {
	background-color: rgba(255, 53, 2, 0.7) !important;
}

.bg-red-transparent-6 {
	background-color: rgba(255, 53, 2, 0.6) !important;
}

.bg-red-transparent-5 {
	background-color: rgba(255, 53, 2, 0.5) !important;
}

.bg-red-transparent-4 {
	background-color: rgba(255, 53, 2, 0.4) !important;
}

.bg-red-transparent-3 {
	background-color: rgba(255, 53, 2, 0.3) !important;
}

.bg-red-transparent-2 {
	background-color: rgba(255, 53, 2, 0.2) !important;
}

.bg-red-transparent-1 {
	background-color: rgba(255, 53, 2, 0.1) !important;
}

.bg-red-darker {
	background-color: #bf2801 !important;
}

.bg-red-lighter {
	background-color: #ff6841 !important;
}

.bg-gradient-red {
	background: rgb(255, 104, 65) !important;
	background: -moz-linear-gradient(top, rgba(255, 104, 65, 1) 0%, rgba(191, 40, 1, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255, 104, 65, 1) 0%, rgba(191, 40, 1, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255, 104, 65, 1) 0%, rgba(191, 40, 1, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6841', endColorstr='#bf2801', GradientType=0) !important;
}

.bg-warning,
.bg-orange {
	background-color: #8c4708 !important;
}

.bg-orange-transparent-9 {
	background-color: rgba(255, 123, 1, 0.9) !important;
}

.bg-orange-transparent-8 {
	background-color: rgba(255, 123, 1, 0.8) !important;
}

.bg-orange-transparent-7 {
	background-color: rgba(255, 123, 1, 0.7) !important;
}

.bg-orange-transparent-6 {
	background-color: rgba(255, 123, 1, 0.6) !important;
}

.bg-orange-transparent-5 {
	background-color: rgba(255, 123, 1, 0.5) !important;
}

.bg-orange-transparent-4 {
	background-color: rgba(255, 123, 1, 0.4) !important;
}

.bg-orange-transparent-3 {
	background-color: rgba(255, 123, 1, 0.3) !important;
}

.bg-orange-transparent-2 {
	background-color: rgba(255, 123, 1, 0.2) !important;
}

.bg-orange-transparent-1 {
	background-color: rgba(255, 123, 1, 0.1) !important;
}

.bg-orange-darker {
	background-color: #bf5c01 !important;
}

.bg-orange-lighter {
	background-color: #ff9c41 !important;
}

.bg-gradient-orange {
	background: rgb(255, 156, 65) !important;
	background: -moz-linear-gradient(top, rgba(255, 156, 65, 1) 0%, rgba(191, 92, 1, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255, 156, 65, 1) 0%, rgba(191, 92, 1, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255, 156, 65, 1) 0%, rgba(191, 92, 1, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9c41', endColorstr='#bf5c01', GradientType=0) !important;
}

.bg-inverse,
.bg-dark,
.bg-black {
	background-color: #000 !important;
}

.bg-black-transparent-9 {
	background-color: rgba(0, 0, 0, 0.9) !important;
}

.bg-black-transparent-8 {
	background-color: rgba(0, 0, 0, 0.8) !important;
}

.bg-black-transparent-7 {
	background-color: rgba(0, 0, 0, 0.7) !important;
}

.bg-black-transparent-6 {
	background-color: rgba(0, 0, 0, 0.6) !important;
}

.bg-black-transparent-5 {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.bg-black-transparent-4 {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

.bg-black-transparent-3 {
	background-color: rgba(0, 0, 0, 0.3) !important;
}

.bg-black-transparent-2 {
	background-color: rgba(0, 0, 0, 0.2) !important;
}

.bg-black-transparent-1 {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-black-darker {
	background-color: #262626 !important;
}

.bg-black-lighter {
	background-color: #666666 !important;
}

.bg-gradient-black {
	background: rgb(102, 102, 102) !important;
	background: -moz-linear-gradient(top, rgba(102, 102, 102, 1) 0%, rgba(38, 38, 38, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(102, 102, 102, 1) 0%, rgba(38, 38, 38, 1) 100%) !important;
	background: linear-gradient(to bottom, #000000c2 0%, rgb(49, 56, 64) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#666666', endColorstr='#262626', GradientType=0) !important;
}

.bg-yellow {
	background-color: #ffd900 !important;
}

.bg-yellow-transparent-9 {
	background-color: rgba(255, 217, 0, 0.9) !important;
}

.bg-yellow-transparent-8 {
	background-color: rgba(255, 217, 0, 0.8) !important;
}

.bg-yellow-transparent-7 {
	background-color: rgba(255, 217, 0, 0.7) !important;
}

.bg-yellow-transparent-6 {
	background-color: rgba(255, 217, 0, 0.6) !important;
}

.bg-yellow-transparent-5 {
	background-color: rgba(255, 217, 0, 0.5) !important;
}

.bg-yellow-transparent-4 {
	background-color: rgba(255, 217, 0, 0.4) !important;
}

.bg-yellow-transparent-3 {
	background-color: rgba(255, 217, 0, 0.3) !important;
}

.bg-yellow-transparent-2 {
	background-color: rgba(255, 217, 0, 0.2) !important;
}

.bg-yellow-transparent-1 {
	background-color: rgba(255, 217, 0, 0.1) !important;
}

.bg-yellow-darker {
	background-color: #bfa300 !important;
}

.bg-yellow-lighter {
	background-color: #fde248 !important;
}

.bg-gradient-yellow {
	background: rgb(253, 226, 72) !important;
	background: -moz-linear-gradient(top, rgba(253, 226, 72, 1) 0%, rgba(255, 217, 0, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(253, 226, 72, 1) 0%, rgba(255, 217, 0, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(253, 226, 72, 1) 0%, rgba(255, 217, 0, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fde248', endColorstr='#ffd900', GradientType=0) !important;
}

.bg-silver {
	background-color: #f0f3f4 !important;
}

.bg-silver-transparent-9 {
	background-color: rgba(240, 243, 244, 0.9) !important;
}

.bg-silver-transparent-8 {
	background-color: rgba(240, 243, 244, 0.8) !important;
}

.bg-silver-transparent-7 {
	background-color: rgba(240, 243, 244, 0.7) !important;
}

.bg-silver-transparent-6 {
	background-color: rgba(240, 243, 244, 0.6) !important;
}

.bg-silver-transparent-5 {
	background-color: rgba(240, 243, 244, 0.5) !important;
}

.bg-silver-transparent-4 {
	background-color: rgba(240, 243, 244, 0.4) !important;
}

.bg-silver-transparent-3 {
	background-color: rgba(240, 243, 244, 0.3) !important;
}

.bg-silver-transparent-2 {
	background-color: rgba(240, 243, 244, 0.2) !important;
}

.bg-silver-transparent-1 {
	background-color: rgba(240, 243, 244, 0.1) !important;
}

.bg-silver-lighter {
	background-color: #f4f6f7 !important;
}

.bg-silver-darker {
	background-color: #b4b6b7 !important;
}

.bg-gradient-silver {
	background: rgb(244, 246, 247) !important;
	background: -moz-linear-gradient(top, rgba(244, 246, 247, 1) 0%, rgba(240, 243, 244, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(244, 246, 247, 1) 0%, rgba(240, 243, 244, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(244, 246, 247, 1) 0%, rgba(240, 243, 244, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f6f7', endColorstr='#f0f3f4', GradientType=0) !important;
}

.bg-grey {
	background-color: #999 !important;
}

.bg-grey-transparent-9 {
	background-color: rgba(153, 153, 153, 0.9) !important;
}

.bg-grey-transparent-8 {
	background-color: rgba(153, 153, 153, 0.8) !important;
}

.bg-grey-transparent-7 {
	background-color: rgba(153, 153, 153, 0.7) !important;
}

.bg-grey-transparent-6 {
	background-color: rgba(153, 153, 153, 0.6) !important;
}

.bg-grey-transparent-5 {
	background-color: rgba(153, 153, 153, 0.5) !important;
}

.bg-grey-transparent-4 {
	background-color: rgba(153, 153, 153, 0.4) !important;
}

.bg-grey-transparent-3 {
	background-color: rgba(153, 153, 153, 0.3) !important;
}

.bg-grey-transparent-2 {
	background-color: rgba(153, 153, 153, 0.2) !important;
}

.bg-grey-transparent-1 {
	background-color: rgba(153, 153, 153, 0.1) !important;
}

.bg-grey-darker {
	background-color: #737373 !important;
}

.bg-grey-lighter {
	background-color: #b3b3b3 !important;
}

.bg-gradient-grey {
	background: rgb(179, 179, 179) !important;
	background: -moz-linear-gradient(top, rgba(179, 179, 179, 1) 0%, rgba(115, 115, 115, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(179, 179, 179, 1) 0%, rgba(115, 115, 115, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(179, 179, 179, 1) 0%, rgba(115, 115, 115, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#737373', GradientType=0) !important;
}

.bg-purple {
	background-color: #9800ff !important;
}

.bg-purple-transparent-9 {
	background-color: rgba(152, 0, 255, 0.9) !important;
}

.bg-purple-transparent-8 {
	background-color: rgba(152, 0, 255, 0.8) !important;
}

.bg-purple-transparent-7 {
	background-color: rgba(152, 0, 255, 0.7) !important;
}

.bg-purple-transparent-6 {
	background-color: rgba(152, 0, 255, 0.6) !important;
}

.bg-purple-transparent-5 {
	background-color: rgba(152, 0, 255, 0.5) !important;
}

.bg-purple-transparent-4 {
	background-color: rgba(152, 0, 255, 0.4) !important;
}

.bg-purple-transparent-3 {
	background-color: rgba(152, 0, 255, 0.3) !important;
}

.bg-purple-transparent-2 {
	background-color: rgba(152, 0, 255, 0.2) !important;
}

.bg-purple-transparent-1 {
	background-color: rgba(152, 0, 255, 0.1) !important;
}

.bg-purple-darker {
	background-color: #7200bf !important;
}

.bg-purple-lighter {
	background-color: #b240ff !important;
}

.bg-gradient-purple {
	background: rgb(178, 64, 255) !important;
	background: -moz-linear-gradient(top, rgba(178, 64, 255, 1) 0%, rgba(114, 0, 191, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(178, 64, 255, 1) 0%, rgba(114, 0, 191, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(178, 64, 255, 1) 0%, rgba(114, 0, 191, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b240ff', endColorstr='#7200bf', GradientType=0) !important;
}

.bg-lime {
	background-color: #c2e900 !important;
}

.bg-lime-transparent-9 {
	background-color: rgba(194, 233, 0, 0.9) !important;
}

.bg-lime-transparent-8 {
	background-color: rgba(194, 233, 0, 0.8) !important;
}

.bg-lime-transparent-7 {
	background-color: rgba(194, 233, 0, 0.7) !important;
}

.bg-lime-transparent-6 {
	background-color: rgba(194, 233, 0, 0.6) !important;
}

.bg-lime-transparent-5 {
	background-color: rgba(194, 233, 0, 0.5) !important;
}

.bg-lime-transparent-4 {
	background-color: rgba(194, 233, 0, 0.4) !important;
}

.bg-lime-transparent-3 {
	background-color: rgba(194, 233, 0, 0.3) !important;
}

.bg-lime-transparent-2 {
	background-color: rgba(194, 233, 0, 0.2) !important;
}

.bg-lime-transparent-1 {
	background-color: rgba(194, 233, 0, 0.1) !important;
}

.bg-lime-darker {
	background-color: #91af00 !important;
}

.bg-lime-lighter {
	background-color: #d1ef40 !important;
}

.bg-gradient-lime {
	background: rgb(209, 239, 64) !important;
	background: -moz-linear-gradient(top, rgba(209, 239, 64, 1) 0%, rgba(145, 175, 0, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(209, 239, 64, 1) 0%, rgba(145, 175, 0, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(209, 239, 64, 1) 0%, rgba(145, 175, 0, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d1ef40', endColorstr='#91af00', GradientType=0) !important;
}

.bg-pink {
	background-color: #ff0081 !important;
}

.bg-pink-transparent-9 {
	background-color: rgba(255, 0, 129, 0.9) !important;
}

.bg-pink-transparent-8 {
	background-color: rgba(255, 0, 129, 0.8) !important;
}

.bg-pink-transparent-7 {
	background-color: rgba(255, 0, 129, 0.7) !important;
}

.bg-pink-transparent-6 {
	background-color: rgba(255, 0, 129, 0.6) !important;
}

.bg-pink-transparent-5 {
	background-color: rgba(255, 0, 129, 0.5) !important;
}

.bg-pink-transparent-4 {
	background-color: rgba(255, 0, 129, 0.4) !important;
}

.bg-pink-transparent-3 {
	background-color: rgba(255, 0, 129, 0.3) !important;
}

.bg-pink-transparent-2 {
	background-color: rgba(255, 0, 129, 0.2) !important;
}

.bg-pink-transparent-1 {
	background-color: rgba(255, 0, 129, 0.1) !important;
}

.bg-pink-darker {
	background-color: #b3005b !important;
}

.bg-pink-lighter {
	background-color: #ff4ca7 !important;
}

.bg-gradient-pink {
	background: rgb(255, 76, 167) !important;
	background: -moz-linear-gradient(top, rgba(255, 76, 167, 1) 0%, rgba(179, 0, 91, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255, 76, 167, 1) 0%, rgba(179, 0, 91, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255, 76, 167, 1) 0%, rgba(179, 0, 91, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4ca7', endColorstr='#b3005b', GradientType=0) !important;
}

.bg-indigo {
	background-color: #5600ff !important;
}

.bg-indigo-transparent-9 {
	background-color: rgba(86, 0, 255, 0.9) !important;
}

.bg-indigo-transparent-8 {
	background-color: rgba(86, 0, 255, 0.8) !important;
}

.bg-indigo-transparent-7 {
	background-color: rgba(86, 0, 255, 0.7) !important;
}

.bg-indigo-transparent-6 {
	background-color: rgba(86, 0, 255, 0.6) !important;
}

.bg-indigo-transparent-5 {
	background-color: rgba(86, 0, 255, 0.5) !important;
}

.bg-indigo-transparent-4 {
	background-color: rgba(86, 0, 255, 0.4) !important;
}

.bg-indigo-transparent-3 {
	background-color: rgba(86, 0, 255, 0.3) !important;
}

.bg-indigo-transparent-2 {
	background-color: rgba(86, 0, 255, 0.2) !important;
}

.bg-indigo-transparent-1 {
	background-color: rgba(86, 0, 255, 0.1) !important;
}

.bg-indigo-darker {
	background-color: #3c00b3 !important;
}

.bg-indigo-lighter {
	background-color: #884cff !important;
}

.bg-gradient-indigo {
	background: rgb(136, 76, 255) !important;
	background: -moz-linear-gradient(top, rgba(136, 76, 255, 1) 0%, rgba(60, 0, 179, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(136, 76, 255, 1) 0%, rgba(60, 0, 179, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(136, 76, 255, 1) 0%, rgba(60, 0, 179, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#884cff', endColorstr='#3c00b3', GradientType=0) !important;
}

.bg-teal {
	background-color: #01caca !important;
}

.bg-teal-transparent-9 {
	background-color: rgba(1, 202, 202, 0.9) !important;
}

.bg-teal-transparent-8 {
	background-color: rgba(1, 202, 202, 0.8) !important;
}

.bg-teal-transparent-7 {
	background-color: rgba(1, 202, 202, 0.7) !important;
}

.bg-teal-transparent-6 {
	background-color: rgba(1, 202, 202, 0.6) !important;
}

.bg-teal-transparent-5 {
	background-color: rgba(1, 202, 202, 0.5) !important;
}

.bg-teal-transparent-4 {
	background-color: rgba(1, 202, 202, 0.4) !important;
}

.bg-teal-transparent-3 {
	background-color: rgba(1, 202, 202, 0.3) !important;
}

.bg-teal-transparent-2 {
	background-color: rgba(1, 202, 202, 0.2) !important;
}

.bg-teal-transparent-1 {
	background-color: rgba(1, 202, 202, 0.1) !important;
}

.bg-teal-darker {
	background-color: #018e8e !important;
}

.bg-teal-lighter {
	background-color: #4ddada !important;
}

.bg-gradient-teal {
	background: rgb(77, 218, 218) !important;
	background: -moz-linear-gradient(top, rgba(77, 218, 218, 1) 0%, rgba(1, 142, 142, 1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(77, 218, 218, 1) 0%, rgba(1, 142, 142, 1) 100%) !important;
	background: linear-gradient(to bottom, rgba(77, 218, 218, 1) 0%, rgba(1, 142, 142, 1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4ddada', endColorstr='#018e8e', GradientType=0) !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-white-transparent-9 {
	background-color: rgba(255, 255, 255, 0.9) !important;
}

.bg-white-transparent-8 {
	background-color: rgba(255, 255, 255, 0.8) !important;
}

.bg-white-transparent-7 {
	background-color: rgba(255, 255, 255, 0.7) !important;
}

.bg-white-transparent-6 {
	background-color: rgba(255, 255, 255, 0.6) !important;
}

.bg-white-transparent-5 {
	background-color: rgba(255, 255, 255, 0.5) !important;
}

.bg-white-transparent-4 {
	background-color: rgba(255, 255, 255, 0.4) !important;
}

.bg-white-transparent-3 {
	background-color: rgba(255, 255, 255, 0.3) !important;
}

.bg-white-transparent-2 {
	background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-white-transparent-1 {
	background-color: rgba(255, 255, 255, 0.1) !important;
}

.text-gradient {
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.no-bg,
.bg-none {
	background: none !important;
}

.height-xs {
	height: 150px !important;
}

.height-sm {
	height: 300px !important;
}

.height-md {
	height: 450px !important;
}

.height-lg {
	height: 600px !important;
}

.height-full {
	height: 100% !important;
}

.height-0 {
	height: 0px !important;
}

.height-10 {
	height: 10px !important;
}

.height-20 {
	height: 20px !important;
}

.height-30 {
	height: 30px !important;
}

.height-40 {
	height: 40px !important;
}

.height-50 {
	height: 50px !important;
}

.height-60 {
	height: 60px !important;
}

.height-70 {
	height: 70px !important;
}

.height-80 {
	height: 80px !important;
}

.height-90 {
	height: 90px !important;
}

.height-100 {
	height: 100px !important;
}

.height-150 {
	height: 150px !important;
}

.height-200 {
	height: 200px !important;
}

.height-250 {
	height: 250px !important;
}

.height-300 {
	height: 300px !important;
}

.height-350 {
	height: 350px !important;
}

.height-400 {
	height: 400px !important;
}

.height-450 {
	height: 450px !important;
}

.height-500 {
	height: 500px !important;
}

.height-550 {
	height: 550px !important;
}

.height-600 {
	height: 600px !important;
}

.width-xs {
	width: 150px !important;
}

.width-sm {
	width: 300px !important;
}

.width-md {
	width: 450px !important;
}

.width-lg {
	width: 600px !important;
}

.width-full {
	width: 100% !important;
}

.width-0 {
	width: 0px !important;
}

.width-10 {
	width: 10px !important;
}

.width-20 {
	width: 20px !important;
}

.width-30 {
	width: 30px !important;
}

.width-40 {
	width: 40px !important;
}

.width-50 {
	width: 50px !important;
}

.width-60 {
	width: 60px !important;
}

.width-70 {
	width: 70px !important;
}

.width-80 {
	width: 80px !important;
}

.width-90 {
	width: 90px !important;
}

.width-100 {
	width: 100px !important;
}

.width-150 {
	width: 150px !important;
}

.width-200 {
	width: 200px !important;
}

.width-250 {
	width: 250px !important;
}

.width-300 {
	width: 300px !important;
}

.width-350 {
	width: 350px !important;
}

.width-400 {
	width: 400px !important;
}

.width-450 {
	width: 450px !important;
}

.width-500 {
	width: 500px !important;
}

.width-550 {
	width: 550px !important;
}

.width-600 {
	width: 600px !important;
}

.pointer-cursor {
	cursor: pointer !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.cursor-initial {
	cursor: initial !important;
}

.cursor-move {
	cursor: move !important;
}

.animated {
	-webkit-animation-duration: .6s;
	animation-duration: .6s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.fade {
	opacity: 0;
	transition: opacity .3s linear;
}

.fade.show {
	opacity: 1.0;
}

.text-ellipsis {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.underline {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.with-shadow {
	box-shadow: 0 12px 36px 6px rgba(0, 0, 0, 0.1) !important;
}



::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(100, 100, 100, 0.3);
}


.tank {
	margin: 5 50px;
	display: inline-block;
	width: 100%;

}

.line {
	fill: none;
	stroke: steelblue;
	stroke-width: 1.5px;
}

.area {
	fill: steelblue;
	clip-path: url(#clip);
}

.zoom {
	cursor: move;
	fill: none;
	pointer-events: all;
}

.boxi {
	position: relative;
	float: left;
	width: 49%;
	margin: 0;
	left: 0;
	max-height: 320px;
}

img {
	max-width: 100%;
	height: auto;
}

.padoso {
	padding-bottom: 50px;
}

.inlineSparkline {
	resize: both;
	overflow: auto;
	margin-left: auto;
	margin-right: auto;

}


.modal {
	padding-right: 0 !important;
}

@media (min-width: 768px) {
	.modal .modal-dialog.modal-top {
		top: 0;
	}

	.modal .modal-dialog.modal-left {
		left: 0;
	}

	.modal .modal-dialog.modal-right {
		height: 100%;
		right: 0;
	}

	.modal .modal-dialog.modal-bottom {
		bottom: 0;
	}

	.modal .modal-dialog.modal-top-left {
		top: 10px;
		left: 10px;
	}

	.modal .modal-dialog.modal-top-right {
		top: 10px;
		right: 10px;
	}

	.modal .modal-dialog.modal-bottom-left {
		bottom: 10px;
		left: 10px;
	}

	.modal .modal-dialog.modal-bottom-right {
		bottom: 10px;
		right: 10px;
	}
}

.modal.fade.top:not(.show) .modal-dialog {
	-webkit-transform: translate3d(0, -25%, 0);
	transform: translate3d(0, -25%, 0);
}

.modal.fade.left:not(.show) .modal-dialog {
	-webkit-transform: translate3d(-25%, 0, 0);
	transform: translate3d(-25%, 0, 0);
}

.modal.fade.right:not(.show) .modal-dialog {
	-webkit-transform: translate3d(25%, 0, 0);
	transform: translate3d(25%, 0, 0);
}

.modal.fade.bottom:not(.show) .modal-dialog {
	-webkit-transform: translate3d(0, 25%, 0);
	transform: translate3d(0, 25%, 0);
}

@media (min-width: 992px) {
	.modal.modal-scrolling {
		position: relative;
	}

	.modal.modal-scrolling .modal-dialog {
		position: fixed;
		z-index: 1050;
	}

	.modal.modal-content-clickable {
		top: auto;
		bottom: auto;
	}

	.modal.modal-content-clickable .modal-dialog {
		position: fixed;
	}

	.modal .modal-fluid {
		width: 100%;
		max-width: 100%;
	}

	.modal .modal-fluid .modal-content {
		width: 100%;
	}

	.modal .modal-frame {
		position: absolute;
		margin: 0;
		width: 100%;
		max-width: 100%;
	}

	.modal .modal-frame.modal-bottom {
		bottom: 0;
	}

	.modal .modal-full-height {
		position: absolute;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin: 0;
		width: 400px;
		min-height: 100%;
		height: auto;
		min-height: 100%;
		top: 0;
		right: 0;
	}

	.modal .modal-full-height.modal-top,
	.modal .modal-full-height.modal-bottom {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
	}

	.modal .modal-full-height.modal-top {
		bottom: auto;
	}

	.modal .modal-full-height.modal-bottom {
		min-height: 0;
		top: auto;
	}

	.modal .modal-full-height .modal-content {
		width: 100%;
	}

	.modal .modal-full-height.modal-lg {
		width: 90%;
		max-width: 90%;
	}
}

@media (min-width: 992px) and (min-width: 992px) {
	.modal .modal-full-height.modal-lg {
		width: 800px;
		max-width: 800px;
	}
}

@media (min-width: 992px) and (min-width: 1200px) {
	.modal .modal-full-height.modal-lg {
		width: 1000px;
		max-width: 1000px;
	}
}

@media (min-width: 992px) {
	.modal .modal-side {
		position: absolute;
		bottom: 10px;
		right: 10px;
		margin: 0;
		width: 400px;
	}
}




.fullDivHeight {
	height: 590px;
}

.sparklineheight {
	height: 590px;
}

.colheight {
	height: 445px;
}

.modalHeight {
	height: 450px;
}
.mapHeight {
	height: 492px;
}

.devicePanelHeight {
	height: 385px;
}

.nvd3Height {
	height: 500px;
}

@media (max-width: 992px) {
	.fullDivHeight {
		height: 400px;
	}

	.sparklineheight {
		height: 100%;
	}

	.colheight {
		height: 100%;
	}

	.modalHeight {
		height: 100%;
	}

	.mapHeight {
		height: 100%;
	}
	/*.devicePanelHeight {*/
	/*	height: 100%;*/
	/*}*/
	.nvd3Height {
		height: 350px;
	}

}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.secondbrand {
	max-height: 100% !important;
	max-width: 100% !important;
}




.modal-90w {
	width: 90%;
	max-width: none !important;
}

.container-line {
	display: inline
}

.extendform {
	height: 100%;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
	padding: 0;
}

.nav>li>a:hover,
.nav>li>a:focus {
	text-decoration: none;
	color: red !important
}

.b-card:hover {
	border: 2px solid #db1c1c !important;
}

@media screen and (max-width: 950px) {

	.modal.left .modal-dialog,
	.modal .modal-dialog {
		width: 100% !important;
		/* height: 70% !important; */
	}
}

.qc_modal .modal-dialog {
	width: 760px !important;
}

@media screen and (min-width: 576px) {
	.qc_modal .modal-dialog {
		max-width: 760px;
		margin: 1.75rem auto;
	}
}

.special_modal .modal-content {
	background-color: #be5757;
	overflow-y: hidden;
}

.special_modal .modal-header {
	border-bottom-color: #000000;
	background-color: rgba(0, 0, 0, 0.86);
	height: 10% !important;
}


@media screen and (max-width: 576px) {
	.modal .modal-dialog {
		position: fixed;
		margin: auto;
		width: 100% !important;
		height: 90%;
		right: 0;
		/* border: #fafafa75 2px solid !important; */

		-webkit-transform: translate3d(0%, 0, 0);
		-ms-transform: translate3d(0%, 0, 0);
		-o-transform: translate3d(0%, 0, 0);
		transform: translate3d(0%, 0, 0);
	}
}




.modal.left .modal-dialog,
.modal .modal-dialog {
	position: fixed;
	margin: auto;
	width: 520px;
	height: 100%;
	right: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal .modal-content {
	height: 100%;
	overflow-y: hidden;
}

.modal.left .modal-body,
.modal .modal-body {
	padding: 15px 15px 80px;
	overflow-y: auto !important;
	height: 85%;
	width: 100%;
}

/*Left*/
.modal.left.fade .modal-dialog {
	left: -320px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
	left: 0;
}

/*Right*/

/* ----- MODAL STYLE ----- */



/* Confirmation Modal */



/* 4.20 Component - Table */

.table {
	border-color: rgba(255, 255, 255, 0.15);
	border-radius: 3px;
	background: none;
}

.table.table-inverse {
	background: none;
}

.table thead th,
.table>thead>tr>th {
	color: #fff;
	font-weight: 600;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
	border-color: rgba(255, 255, 255, 0.15);
	color: rgba(255, 255, 255, .9);
	padding: 10px 15px;
}

.table-condensed>thead>tr>th,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>tbody>tr>td,
.table-condensed>tfoot>tr>td {
	padding: 7px 15px;
}

.table-hover>tbody>tr:hover>td,
.table-hover>tbody>tr:hover>th {
	background: rgba(255, 255, 255, 0.15) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
	background: rgba(255, 255, 255, 0.15);
	border-color: transparent;
}

.table-striped.table-bordered>tbody>tr:nth-child(odd)>td,
.table-striped.table-bordered>tbody>tr:nth-child(odd)>th {
	border-color: rgba(255, 255, 255, 0.15);
}

.table.table-inverse {
	color: rgba(255, 255, 255, 0.75);
}

.table.table-inverse>thead>tr>th,
.table.table-inverse>tbody>tr>th,
.table.table-inverse>tfoot>tr>th {
	color: #fff;
}

.flat-black .table.table-inverse>thead>tr>th,
.table.table-inverse>thead>tr>th,
.table.table-inverse>tbody>tr>th,
.table.table-inverse>tfoot>tr>th,
.table.table-inverse>thead>tr>td,
.table.table-inverse>tbody>tr>td,
.table.table-inverse>tfoot>tr>td {
	border-color: #444 !important;
	border-color: rgba(255, 255, 255, 0.15) !important;
	background: none !important;
}

.table>thead>tr>td.info,
.table>tbody>tr>td.info,
.table>tfoot>tr>td.info,
.table>thead>tr>th.info,
.table>tbody>tr>th.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>tbody>tr.info>td,
.table>tfoot>tr.info>td,
.table>thead>tr.info>th,
.table>tbody>tr.info>th,
.table>tfoot>tr.info>th {
	background: rgba(59, 240, 240, 0.5);
	color: #fff;
	border-color: rgba(0, 0, 0, 0.15);
}

.table>thead>tr>td.success,
.table>tbody>tr>td.success,
.table>tfoot>tr>td.success,
.table>thead>tr>th.success,
.table>tbody>tr>th.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>tbody>tr.success>td,
.table>tfoot>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr.success>th,
.table>tfoot>tr.success>th {
	background: rgba(51, 250, 2, 0.5);
	color: #fff;
	border-color: rgba(0, 0, 0, 0.15);
}

.table>thead>tr>td.danger,
.table>tbody>tr>td.danger,
.table>tfoot>tr>td.danger,
.table>thead>tr>th.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>tbody>tr.danger>td,
.table>tfoot>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr.danger>th,
.table>tfoot>tr.danger>th {
	background: rgba(255, 53, 2, 0.5);
	border-color: rgba(0, 0, 0, 0.15);
	color: #fff;
}

.table>thead>tr>td.warning,
.table>tbody>tr>td.warning,
.table>tfoot>tr>td.warning,
.table>thead>tr>th.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>tbody>tr.warning>td,
.table>tfoot>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr.warning>th,
.table>tfoot>tr.warning>th {
	background: rgba(255, 124, 0, 0.5);
	border-color: rgba(0, 0, 0, 0.15);
	color: #fff;
}

.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
	background: rgba(255, 255, 255, 0.15);
	border-color: rgba(0, 0, 0, 0.15);
	color: #fff;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
	border-top: 0;
}

.table.table-bordered>thead:first-child>tr:first-child>td,
.table.table-bordered>thead:first-child>tr:first-child>th {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.table .with-form-control .form-control,
.table .with-input-group .input-group {
	margin-top: -8px;
	margin-bottom: -8px;
}

.table-condensed .with-form-control .form-control,
.table-condensed .with-input-group .input-group {
	margin-top: -6px;
	margin-bottom: -5px;
}

.table .with-img img,
.table .with-btn .btn,
.table .with-btn-group .btn-group {
	margin-top: -8px;
	margin-bottom: -7px;
}

.table .with-checkbox .checkbox,
.table .with-radio .radio {
	margin: 0;
	padding: 0;
}

.table .with-checkbox .checkbox-css label,
.table .with-radio .radio-css label {
	min-height: inherit;
}

.table .with-checkbox .checkbox-css input+label:before,
.table .with-checkbox .checkbox-css input:checked+label:after,
.table .with-radio .radio-css input+label:before {
	top: 0;
}

.table .with-radio .radio-css input:checked+label:after {
	top: 5px;
}

/* 4.15 Component - Panel */



/* 4.17 Component - Panel - Panel loading */

.panel.panel-loading .panel-body {
	position: relative;
	z-index: 0;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.panel.panel-loading .panel-body .panel-loader {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #fff;
	opacity: 0.9;
	-webkit-animation: fadeIn .2s;
	animation: fadeIn .2s;
	z-index: 1020;
	border-radius: 0 0 4px 4px;
}

#loading {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	display: block;
	opacity: 0.7;
	background-color: #fff;
	z-index: 99;
	text-align: center;
}

#loading-image {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	color: rgba(255, 255, 255, .9)
}

.h1,
h1 {
	font-size: 36px
}

.h2,
h2 {
	font-size: 30px
}

.h3,
h3 {
	font-size: 24px
}

.h4,
h4 {
	font-size: 18px
}

.h5,
h5 {
	font-size: 14px
}

.h6,
h6 {
	font-size: 12px
}


.badge {
	border-radius: 0.25em !important;
	padding: 0.2em 0.6em 0.3em !important;
	font-size: 75% !important;
	font-weight: 600 !important;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25em;
}


.fullDivHeight {
	height: 590px;
}

.sparklineheight {
	height: 590px;
}



.modalHeight {
	height: 450px;
}

.nvd3Height {
	height: 500px;
}

/* @media (max-width: 992px) {
	.fullDivHeight {
		height: 400px;
	}

	.sparklineheight {
		height: 100%;
	}

	.colheight {
		height: 100%;
	}

	.modalHeight {
		height: 100%;
	}

	.nvd3Height {
		height: 350px;
	}

} */

.panel {
	border-radius: 8px;
}



.panel .panel-body {
	overflow-y: auto !important;
	overflow-x: hidden !important;
	border: 1px solid #595c5f;
	border-radius: 8px;
}

.nav-link.active {
	color: #f0f3f4 !important;
	background-color: #000000 !important;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	background-color: #000;
}


.graph_modal {
	position: absolute !important;
	max-width: 100% !important;
	width: 100% !important;
	top: auto !important;

}

.confirm {
	max-height: 50% !important;
	bottom: 50%;
	top: 50%;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.confirm .modal-content {
	height: auto;
	overflow-y: auto;
}

.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Full height */
}

.modal-content {
    border-radius: 15px; /* Rounded corners */
    border: 1px solid #888; /* Smoother border */
    background-color: #212529; /* Dark background */
    color: #fff; /* White text */
}

.modal-header {
    border-bottom: 1px solid #444; /* Border for header */
    border-top-left-radius: 15px; /* Rounded corners for header */
    border-top-right-radius: 15px; /* Rounded corners for header */
    background-color: #000000; /* Darker background for header */
}

.modal-footer {
    border-top: 1px solid #444; /* Border for footer */
    border-bottom-left-radius: 15px; /* Rounded corners for footer */
    border-bottom-right-radius: 15px; /* Rounded corners for footer */
    background-color: #000000; /* Slightly darker background for footer */
}







@media (min-width: 900px) {
	.graph_modal {
		bottom: 0 !important;
		min-height: 0 !important;

		height: auto !important;
		top: auto !important;
		margin: 0 !important;
		/* border-top: #fafafa75 2px solid !important; */
		/* box-shadow: -10px 1px 5px -5px #e6e6e647 !important; */
	}

}

.w-responsive-40 {
	width: 40%;
	max-width: 125px !important;
}




.blur-component {
	filter: blur(20px);
}

.apexcharts-menu {
	background: #1a1a1a !important;
}

.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item:hover,
.apexcharts-canvas .apexcharts-menu .apexcharts-menu-item:focus {
	background: #494444 !important;
}

.disabled-button {
	pointer-events: none;
	opacity: 0.65;
}



/* -------------------------------
   Right Side Panel
------------------------------- */

.theme-panel {
	position: fixed;
	right: -175px;
	top: 150px;
	z-index: 1020;
	padding: 15px;
	width: 175px;
	-webkit-transition: right .2s linear;
	-moz-transition: right .2s linear;
	-ms-transition: right .2s linear;
	-o-transition: right .2s linear;
	transition: right .2s linear
}

.theme-panel .theme-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.theme-panel .theme-list+.theme-list {
	margin-top: 10px
}

.theme-panel .theme-list>li+li {
	margin-left: 5px
}

.theme-panel .theme-list>li>a {
	width: 20px;
	height: 20px;
	display: block;
	text-decoration: none;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear
}

.theme-panel .theme-list>li>a:focus,
.theme-panel .theme-list>li>a:hover {
	opacity: .6
}

.theme-panel .theme-list>li.active>a {
	position: relative
}

.theme-panel .theme-list>li.active>a:before {
	content: '\f00c';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	font-size: 14px;
	color: #fff;
	opacity: .4;
	text-align: center;
	font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto
}

.theme-panel .theme-list>li.active>a.bg-white:before {
	color: #000
}

.theme-panel .theme-collapse-btn {
	position: absolute;
	left: -50px;
	margin-top: -110px;
	width: 50px;
	height: 30px;
	line-height: 30px;
	font-size: 18px;
	color: #fff;
	/*background: rgba(64, 64, 64, .95);*/
	background: rgba(0, 0, 0);
	text-align: center;
	-webkit-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
	-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15)
}

@media (max-width:767.98px) {
	.theme-panel .theme-collapse-btn {
		top: 35%
	}
}

.theme-panel .theme-panel-content {
	margin: -15px;
	padding: 15px;
	background: #111111;
	position: relative;
	z-index: 10
}

.theme-panel .theme-version {
	margin: 0 0 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.theme-panel .theme-version a {
	width: calc(50% - 2.5px);
	padding-top: 35%;
	position: relative;
	text-decoration: none
}

.theme-panel .theme-version a+a {
	margin-left: 5px
}

.theme-panel .theme-version a span {
	font-size: 14px;
	font-weight: 700;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top left
}

.theme-panel .theme-version a.active:before,
.theme-panel .theme-version a:hover:before {
	content: '';
	position: absolute;
	border: 12px solid transparent;
	border-left-color: #737373;
	border-top-color: #737373;
	left: 0;
	top: 0;
	z-index: 10
}

.theme-panel .theme-version a.active:after,
.theme-panel .theme-version a:hover:after {
	content: '\f00c';
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	z-index: 10;
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 10px;
	text-align: center;
	font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto
}

.theme-panel .theme-version a.active:before {
	border-left-color: #007aff;
	border-top-color: #007aff
}

.theme-panel .control-label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center
}

.theme-panel .divider {
	height: 1px;
	margin: 15px 0;
	background: rgba(89, 89, 89, .95)
}

.theme-panel.active {
	right: 0;
	-webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, .3);
	box-shadow: 0 5px 25px #a5b8ca
}

.theme-panel.active .theme-collapse-btn .fa:before {
	content: '\f00d'
}

.theme-panel.active a.bg-white {
	border: 1px solid #999
}

.theme-panel.theme-panel-lg {
	top: 0;
	bottom: 0;
	width: 460px;
	right: -460px;
	-webkit-border-radius: 0;
	border-radius: 0
}

.theme-panel.theme-panel-lg .theme-list {
	margin-bottom: -3px
}

.theme-panel.theme-panel-lg .theme-list>li {
	margin-right: 3px;
	margin-bottom: 3px
}

.theme-panel.theme-panel-lg .theme-list>li+li {
	margin-left: 0
}

.theme-panel.theme-panel-lg .theme-list>li>a {
	width: 28px;
	height: 28px;
	-webkit-border-radius: 40px;
	border-radius: 40px
}

.theme-panel.theme-panel-lg .theme-list>li>a:before {
	line-height: 28px
}

.theme-panel.theme-panel-lg .theme-version {
	margin: 0 0 5px
}

.theme-panel.theme-panel-lg .theme-panel-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	margin: 0;
	padding: 20px
}

.theme-panel.theme-panel-lg .theme-collapse-btn {
	top: 10%
}

@media (max-width:767.98px) {
	.theme-panel.theme-panel-lg .theme-collapse-btn {
		top: 15%
	}

	.theme-panel.theme-panel-lg {
		top: 0;
		bottom: 0;
		width: 80%;
		right: -80%;
		-webkit-border-radius: 0;
		border-radius: 0
	}

}

.theme-panel.theme-panel-lg.active {
	right: 0
}

/* -------------------------------
   Left Side Panel
------------------------------- */


.left-theme-panel {
	position: fixed;
	left: -175px;
	top: 150px;
	z-index: 1020;
	padding: 15px;
	width: 175px;
	-webkit-transition: left .2s linear;
	-moz-transition: left .2s linear;
	-ms-transition: left .2s linear;
	-o-transition: left .2s linear;
	transition: left .2s linear;
}

.left-theme-panel .theme-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.left-theme-panel .theme-list+.theme-list {
	margin-top: 10px;
}

.left-theme-panel .theme-list>li+li {
	margin-left: 5px;
}

.left-theme-panel .theme-list>li>a {
	width: 20px;
	height: 20px;
	display: block;
	text-decoration: none;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-transition: all .2s linear;
	-moz-transition: all .2s linear;
	-ms-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
}

.left-theme-panel .theme-list>li>a:focus,
.left-theme-panel .theme-list>li>a:hover {
	opacity: .6;
}

.left-theme-panel .theme-list>li.active>a {
	position: relative;
}

.left-theme-panel .theme-list>li.active>a:before {
	content: '\f00c';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	font-size: 14px;
	color: #fff;
	opacity: .4;
	text-align: center;
	font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
}

.left-theme-panel .theme-list>li.active>a.bg-white:before {
	color: #000;
}

.left-theme-panel .theme-collapse-btn {
	position: absolute;
	right: -50px;
	top: 10%;
	margin-top: -15px;
	width: 50px;
	height: 30px;
	line-height: 30px;
	font-size: 18px;
	color: #fff;
	background: rgba(64, 64, 64, .95);
	text-align: center;
	-webkit-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
	-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
}

@media (max-width:767.98px) {
	.left-theme-panel .theme-collapse-btn {
		top: 35%;
	}
}

.left-theme-panel .theme-panel-content {
	/*margin: -15px;*/
	padding-top: 15px;
	background: #26303a;
	position: relative;
	z-index: 10;
}

.left-theme-panel .theme-version {
	margin: 0 0 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.left-theme-panel .theme-version a {
	width: calc(50% - 2.5px);
	padding-top: 35%;
	position: relative;
	text-decoration: none;
}

.left-theme-panel .theme-version a+a {
	margin-left: 5px;
}

.left-theme-panel .theme-version a span {
	font-size: 14px;
	font-weight: 700;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top left;
}

.left-theme-panel .theme-version a.active:before,
.left-theme-panel .theme-version a:hover:before {
	content: '';
	position: absolute;
	border: 12px solid transparent;
	border-left-color: #737373;
	border-top-color: #737373;
	left: 0;
	top: 0;
	z-index: 10;
}

.left-theme-panel .theme-version a.active:after,
.left-theme-panel .theme-version a:hover:after {
	content: '\f00c';
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	z-index: 10;
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 10px;
	text-align: center;
	font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Pro, FontAwesome !important;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
}

.left-theme-panel .theme-version a.active:before {
	border-left-color: #007aff;
	border-top-color: #007aff;
}

.left-theme-panel .control-label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.left-theme-panel .divider {
	height: 1px;
	margin: 15px 0;
	background: rgba(89, 89, 89, .95);
}

.left-theme-panel.active {
	left: 0;
	-webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, .3);
	/*box-shadow: 0 5px 25px #a5b8ca;*/
}

.left-theme-panel.active a.bg-white {
	border: 1px solid #999;
}

.left-theme-panel.theme-panel-lg {
	top: 0;
	bottom: 0;
	width: 320px;
	left: -320px;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.left-theme-panel.theme-panel-lg .theme-list {
	margin-bottom: -3px;
}

.left-theme-panel.theme-panel-lg .theme-list>li {
	margin-right: 3px;
	margin-bottom: 3px;
}

.left-theme-panel.theme-panel-lg .theme-list>li+li {
	margin-left: 0;
}

.left-theme-panel.theme-panel-lg .theme-list>li>a {
	width: 28px;
	height: 28px;
	-webkit-border-radius: 40px;
	border-radius: 40px;
}

.left-theme-panel.theme-panel-lg .theme-list>li>a:before {
	line-height: 28px;
}

.left-theme-panel.theme-panel-lg .theme-version {
	margin: 0 0 5px;
}

.left-theme-panel.theme-panel-lg .theme-panel-content {
	position: absolute;
	background: #111111;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	margin: 0;
	/*padding: 20px;*/
}

.left-theme-panel.theme-panel-lg .theme-collapse-btn {
	top: 10%;
}

@media (max-width:767.98px) {
	.left-theme-panel.theme-panel-lg .theme-collapse-btn {
		top: 15%;
	}

	.left-theme-panel.theme-panel-lg {
		top: 0;
		bottom: 0;
		width: 80%;
		left: -80%;
		-webkit-border-radius: 0;
		border-radius: 0;
	}
}

.left-theme-panel.theme-panel-lg.active {
	left: 0;
}



/* 4.36 Component - Bootstrap 4 - Cards (Added in V3.0) */

.card {
	position: relative;
	display: block;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: "#212529";
	border: none;
	border-radius: 15px;
	margin-bottom: 5px;

}

.card.grow {
	transition: all 0.2s ease-in-out;
}

.card.grow:hover {
	transform: scale(1.04);
	background-color: #000;
}

.card-accordion .card+.card {
	margin-top: -10px;
}

.card-block {
	padding: 15px;
}

.card-title {
	margin-bottom: 15px;
}

.card-subtitle {
	margin-top: -5px;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link:hover {
	text-decoration: none;
}

.card-link+.card-link {
	margin-left: 30px;
}

.card>.list-group:first-child .list-group-item:first-child {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

.card>.list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.card-header {
	padding: 10px 15px;
	margin-bottom: 0;
	margin-top: 0;
	background-color: rgba(0, 0, 0, 0.25);
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	font-weight: 600;
}

.card-header-link,
a.card-header-link {
	margin: -10px -15px;
	padding: 10px 15px;
	display: block;
	text-decoration: none;
}

.card-header:first-child {
	border-radius: 4px 4px 0 0;
}

.card-header.collapsed {
	border-radius: 4px;
}

.card-footer {
	padding: 10px 15px;
	background-color: rgba(0, 0, 0, 0.15);
	border-top: 1px solid rgba(255, 255, 255, 0.15);
	font-weight: 600;
}

.card-footer:last-child {
	border-radius: 0 0 4px 4px;
}

.card-header-tabs {
	margin-right: -5px;
	margin-bottom: -5px;
	margin-left: -5px;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -5px;
	margin-left: -5px;
	margin-bottom: 0px;
}

.card-primary {
	background-color: #006cff;
	border-color: #006cff;
}

.card-primary .card-header,
.card-primary .card-footer {
	background-color: transparent;
}

.card-success {
	background-color: #04c142;
	border-color: #04c142;
}

.card-success .card-header,
.card-success .card-footer {
	background-color: transparent;
}

.card-info {
	background-color: #00cbff;
	border-color: #00cbff;
}

.card-info .card-header,
.card-info .card-footer {
	background-color: transparent;
}

.card-warning {
	background-color: #ff7b01;
	border-color: #ff7b01;
}

.card-warning .card-header,
.card-warning .card-footer {
	background-color: transparent;
}

.card-danger {
	background-color: #ff3502;
	border-color: #ff3502;
}

.card-danger .card-header,
.card-danger .card-footer {
	background-color: transparent;
}

.card-outline-primary {
	background-color: transparent;
	border-color: #006cff;
}

.card-outline-secondary {
	background-color: transparent;
	border-color: #999;
}

.card-outline-info {
	background-color: transparent;
	border-color: #00cbff;
}

.card-outline-success {
	background-color: transparent;
	border-color: #04c142;
}

.card-outline-warning {
	background-color: transparent;
	border-color: #ff7b01;
}

.card-outline-danger {
	background-color: transparent;
	border-color: #ff3502;
}

.card-inverse {
	color: rgba(255, 255, 255, 0.65);
}

.card-inverse .card-header,
.card-inverse .card-footer {
	background-color: transparent;
	border-color: rgba(255, 255, 255, 0.2);
}

.card-inverse .card-header,
.card-inverse .card-footer,
.card-inverse .card-title,
.card-inverse .card-blockquote {
	color: #fff;
}

.card-inverse .card-link,
.card-inverse .card-text,
.card-inverse .card-subtitle,
.card-inverse .card-blockquote .blockquote-footer {
	color: rgba(255, 255, 255, 0.65);
}

.card-inverse .card-link:focus,
.card-inverse .card-link:hover {
	color: #fff;
}

.card-blockquote {
	padding: 0;
	margin-bottom: 0;
	border-left: 0;
}

.card-img {
	border-radius: 4px;
	max-width: 100%;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 15px;
}

.card-img-top {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	max-width: 100%;
}

.card-img-bottom {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	max-width: 100%;
}

.card.card-inverse footer {
	color: rgba(255, 255, 255, 0.75);
}

.card[class*="card-outline-"] {
	border-width: 1px;
	border-style: solid;
}

.nav-tabs.card-header-tabs>li>a {
	border: none !important;
}

.nav-tabs.card-header-tabs>li>a:hover,
.nav-tabs.card-header-tabs>li>a:focus {
	background: none;
}

.nav-tabs.card-header-tabs>li>a.active,
.nav-tabs.card-header-tabs>li.active>a {
	border-bottom-color: transparent !important;
	background: rgba(255, 255, 255, 0.25) !important;
}

.nav.nav-pills.card-header-pills .nav-item .active {
	background: rgba(255, 255, 255, 0.25);
}

.nav-link.disabled {
	color: rgba(255, 255, 255, 0.75);
}


.close {
	float: right;
	font-size: 0.8rem;
	font-weight: 700;
	line-height: 1;
	color: #f8f9fa;
	text-shadow: 0 1px 0 #fff;
	opacity: .5
}

.close:not(:disabled):not(.disabled) {
	cursor: pointer
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
	color: #ff3502;
	text-decoration: none;
	opacity: .75
}

.nav-link {
	color: #ffffff !important;
}


.main-container {
	transition: transform 0.1s ease-in-out, width 0.1s ease-in-out;
	margin-left: 0px;
}



.shifted-content {
	transform: translateX(320px);
	position: relative;
	width: calc(100% - 320px);
}

@media screen and (max-width: 576px) {
	.shifted-content {
		transform: none;
		width: auto;
	}
}



.nav-link:hover {
	color: #198754 !important;
}


.dropdown-menu.show {
	display: block;
	max-height: 300px;
	overflow-y: scroll;
}


#water #waveShape {
	animation-name: waveAction01;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-duration: 2s;
}

@keyframes waveAction01 {
	0% {
		transform: translate(300px, -1830px);
	}

	100% {
		transform: translate(102px, -1830px);
	}
}

.btn-outline-success {
	--bs-btn-color: #31e491;
	--bs-btn-border-color: #31e491;
}

.rdtPicker {
    background-color: #333;
    color: #fff;
}

.rdtPicker .rdtTimeToggle, 
.rdtPicker .rdtSwitch, 
.rdtPicker .rdtPrev, 
.rdtPicker .rdtNext {
    color: #fff;
}

.rdtPicker td.rdtDay:hover, 
.rdtPicker td.rdtHour:hover, 
.rdtPicker td.rdtMinute:hover, 
.rdtPicker td.rdtSecond:hover, 
.rdtPicker td.rdtMonth:hover, 
.rdtPicker td.rdtYear:hover {
    background-color: #555;
}

.rdtPicker td.rdtActive, 
.rdtPicker td.rdtActive:hover {
    background-color: #007bff;
    color: #fff;
}

.rdt .rdtPicker .rdtDays .rdtDay {
    color: #ddeade;
}

.rdt .rdtPicker table tfoot tr td.rdtTimeToggle {
    color: #ddeade;
}

.rdt .rdtPicker .rdtTime .rdtCounters .rdtCounter .rdtCount {
    color: #ddeade;
}

