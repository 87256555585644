.set {
    overflow: hidden;
    padding: 20px;
    text-align: center;
 
}

.d-pad {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 48%;
    overflow: hidden;
    left: 10px;
    bottom: 20%;
}

.d-pad:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 5%;
    transform: translate(-50%, -50%);
    width: 66.6%;
    height: 66.6%;
    background: #ddd;
}

.d-pad:after {
    content: '';
    position: absolute;
    display: none;
    z-index: 2;
    width: 20%;
    height: 20%;
    top: 50%;
    left: 50%;
    background: #ddd;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s;
    cursor: pointer;
}

.d-pad:hover:after {
    width: 30%;
    height: 30%;
}

.d-pad a {
    display: block;
    position: absolute;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 33.3%;
    height: 43%;
    line-height: 40%;
    color: #fff;
    background: #ddd;
    text-align: center;
}

.d-pad a:hover {
    background: #eee;
}

.d-pad a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 5px;
    border-style: solid;
    transition: all 0.25s;
}

.d-pad a:after {
    content: '';
    position: absolute;
    width: 102%;
    height: 78%;
    background: #fff;
    border-radius: 17%;
}

.d-pad a.left,
.d-pad a.right {
    width: 43%;
    height: 33%;
}

.d-pad a.left:after,
.d-pad a.right:after {
    width: 78%;
    height: 102%;
}

.d-pad a.up {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 17% 17% 50% 50%;
}

.d-pad a.up:hover {
    background: linear-gradient(0deg, #ddd 0%, #eee 50%);
}

.d-pad a.up:after {
    left: 0;
    top: 0;
    transform: translate(-100%, 0);
    border-top-left-radius: 50%;
    pointer-events: none;
}

.d-pad a.up:before {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 0 13px 19px 13px;
    border-color: transparent transparent #aaa transparent;
}

.d-pad a.up:active:before {
    border-bottom-color: #333;
}

.d-pad a.up:hover:before {
    top: 35%;
}

.d-pad a.down {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50% 50% 17% 17%;
}

.d-pad a.down:hover {
    background: linear-gradient(180deg, #ddd 0%, #eee 50%);
}

.d-pad a.down:after {
    right: 0;
    bottom: 0;
    transform: translate(100%, 0);
    border-bottom-right-radius: 50%;
    pointer-events: none;
}

.d-pad a.down:before {
    bottom: 40%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-width: 19px 13px 0 13px;
    border-color: #aaa transparent transparent transparent;
}

.d-pad a.down:active:before {
    border-top-color: #333;
}

.d-pad a.down:hover:before {
    bottom: 35%;
}

.d-pad a.left {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 17% 50% 50% 17%;
}

.d-pad a.left:hover {
    background: linear-gradient(-90deg, #ddd 0%, #eee 50%);
}

.d-pad a.left:after {
    left: 0;
    bottom: 0;
    transform: translate(0, 100%);
    border-bottom-left-radius: 50%;
    pointer-events: none;
}

.d-pad a.left:before {
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-width: 13px 19px 13px 0;
    border-color: transparent #aaa transparent transparent;
}

.d-pad a.left:active:before {
    border-right-color: #333;
}

.d-pad a.left:hover:before {
    left: 35%;
}

.d-pad a.right {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    border-radius: 50% 17% 17% 50%;
}

.d-pad a.right:hover {
    background: linear-gradient(90deg, #ddd 0%, #eee 50%);
}

.d-pad a.right:after {
    right: 0;
    top: 0;
    transform: translate(0, -100%);
    border-top-right-radius: 50%;
    pointer-events: none;
}

.d-pad a.right:before {
    right: 40%;
    top: 50%;
    transform: translate(50%, -50%);
    border-width: 13px 0 13px 19px;
    border-color: transparent transparent transparent #aaa;
}

.d-pad a.right:active:before {
    border-left-color: #333;
}

.d-pad a.right:hover:before {
    right: 35%;
}

.d-pad.up a.up:before {
    border-bottom-color: #333;
}

.d-pad.down a.down:before {
    border-top-color: #333;
}

.d-pad.left a.left:before {
    border-right-color: #333;
}

.d-pad.right a.right:before {
    border-left-color: #333;
}

.dark .d-pad a {
    border-radius: 35%;
}

.dark .d-pad:before,
.dark .d-pad a {
    background: #111;
}

.dark .d-pad a.up:hover {
    background: linear-gradient(0deg, #111 0%, #000 50%);
}

.dark .d-pad a.right:hover {
    background: linear-gradient(90deg, #111 0%, #000 50%);
}

.dark .d-pad a.down:hover {
    background: linear-gradient(180deg, #111 0%, #000 50%);
}

.dark .d-pad a.left:hover {
    background: linear-gradient(-90deg, #111 0%, #000 50%);
}

.dark .d-pad a.up:before {
    border-bottom-color: rgba(255, 255, 255, 0.9);
}

.dark .d-pad a.right:before {
    border-left-color: rgba(255, 255, 255, 0.9);
}

.dark .d-pad a.down:before {
    border-top-color: rgba(255, 255, 255, 0.9);
}

.dark .d-pad a.left:before {
    border-right-color: rgba(255, 255, 255, 0.9);
}

.dark .d-pad a.up:active:before {
    border-bottom-color: #61e22d;
}

.dark .d-pad a.right:active:before {
    border-left-color: #61e22d;
}

.dark .d-pad a.down:active:before {
    border-top-color: #61e22d;
}

.dark .d-pad a.left:active:before {
    border-right-color: #61e22d;
}


.setbg.white .d-pad:before,
.setbg.white .d-pad a {
    background: #fff;
}

.setbg.white .d-pad:after {
    display: block;
    background: rgba(0, 0, 0, 0.1);
}

.setbg.white .d-pad a:after {
    border-radius: 40%;
    background: #000;
}

.setbg.white .d-pad a.up:hover,
.setbg.white .d-pad a.right:hover,
.setbg.white .d-pad a.down:hover,
.setbg.white .d-pad a.left:hover {
    background: #fff;
}

.setbg.white .d-pad a.up:before {
    border-bottom-color: #0074D9;
}

.setbg.white .d-pad a.right:before {
    border-left-color: #0074D9;
}

.setbg.white .d-pad a.down:before {
    border-top-color: #0074D9;
}

.setbg.white .d-pad a.left:before {
    border-right-color: #0074D9;
}

.setbg.white .d-pad a.up:active:before,
.setbg.white .d-pad a.right:active:before,
.setbg.white .d-pad a.down:active:before,
.setbg.white .d-pad a.left:active:before {
    border-color: rgba(0, 0, 0, 0.6);
}

.setbg.white .o-pad {
    background: #fff;
}

.setbg.white .o-pad a {
    border-color: rgba(255, 255, 255, 0.6);
}

.setbg.white .o-pad:before,
.setbg.white .o-pad a {
    background: #fff;
}

.setbg.white .o-pad:after {
    display: block;
    background: #ccc;
}

.setbg.white .o-pad a.up:after {
    border-bottom-color: #2ECC40;
}

.setbg.white .o-pad a.right:after {
    border-left-color: #85144b;
}

.setbg.white .o-pad a.down:after {
    border-top-color: #7FDBFF;
}

.setbg.white .o-pad a.left:after {
    border-right-color: #B10DC9;
}

.setbg.white .o-pad a.up:active:after,
.setbg.white .o-pad a.right:active:after,
.setbg.white .o-pad a.down:active:after,
.setbg.white .o-pad a.left:active:after {
    border-color: rgba(0, 0, 0, 0.6);
}