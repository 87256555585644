body {
  background: #333 !important;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #fff !important;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.alertDiv {
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  width: 100%;
}

.close ::before {
  content: 'X';
  visibility: visible;
  color: "black";
  font-weight: bold;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 32px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: rgb(247, 12, 12, 0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding: 0;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  color:  #abf2d2 !important;;
}


input {
  color-scheme: dark;
}






/* .backgroundImage {
  background-image: url('/images/background.png');
  background-position: center bottom, left top;
  -webkit-background-size: 80px 60px, cover;
  -moz-background-size: 80px 60px, cover;
  -o-background-size: 80px 60px, cover;
  background-size: 80px 60px, cover;
  background-repeat: repeat-x, no-repeat;
  width: 100%;
  min-height: 100vh;
} */


@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
