.nav-wizards-container {
	@include media-breakpoint-down(md) {
		overflow: scroll;
		
		& .nav.nav-wizards-1,
		& .nav.nav-wizards-2,
		& .nav.nav-wizards-3 {
			min-width: rem(600px);
		}
	}
}

.nav {
	&.nav-wizards-1 {
		@include flex-wrap(nowrap);
		
		& .nav-item {
			padding: 0;
			
			& .nav-link {
				position: relative;
				color: $gray-700;
				
				& .nav-no {
					width: rem(40px);
					height: rem(40px);
					margin: 0 auto;
					font-weight: $font-weight-bold;
					background: none;
					border: 2px solid $gray-700;
					position: relative;
					z-index: 10;
					font-size: $font-size-lg;
					
					@include border-radius(40px);
					@include display-flex();
					@include flex-align(center);
					@include flex-justify-content(center);
					@include transition(all .2s linear);
				}
				& .nav-text {
					text-align: center;
					margin-top: $spacer / 2;
					font-weight: $font-weight-bold;
					font-size: $font-size-base;
				}
				&.active,
				&.completed {
					color: $white;
					
					& .nav-no {
						border-color: $white;
						color: $white;
					}
					&:before,
					&:after {
						background: $white;
					}
					&:after {
						width: calc(50% - 20px);
					}
				}
				&.active {
					& .nav-no {
						@include box-shadow(0 0 0 5px rgba($white, .2));
					}
				}
				&.disabled {
					color: $gray-600;
				}
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: $nav-link-padding-y + rem-default(19px);
					height: rem(2px);
					background: $gray-700;
					
					@if $enable-rtl {
						right: 0;
						left: calc(50% + 20px);
					} @else {
						left: 0;
						right: calc(50% + 20px);
					}
				}
				&:after {
					@if $enable-rtl {
						left: 0;
						right: calc(50% + 20px);
					} @else {
						left: calc(50% + 20px);
						right: 0;
					}
					
					@include transition(all .2s linear);
				}
			}
			&:first-child {
				& .nav-link {
					&:before,
					&:after {
						@if $enable-rtl {
							right: calc(50% + 20px);
						} @else {
							left: calc(50% + 20px);
						}
					}
				}
			}
			&:last-child {
				& .nav-link {
					&:before {
						@if $enable-rtl {
							left: calc(50% + 20px);
						} @else {
							right: calc(50% + 20px);
						}
					}
					&.active,
					&.completed {
						&:before {
							background: $white;
						}
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&.nav-wizards-2 {
		@include flex-wrap(nowrap);
		
		& .nav-item {
			padding: 0 $nav-link-padding-x;
			
			& .nav-link {
				position: relative;
				color: $gray-800;
				background: $gray-700;
				
				@include border-radius(60px);
				
				& .nav-text {
					text-align: center;
					font-weight: $font-weight-bold;
					font-size: $font-size-base;
					position: relative;
					z-index: 10;
					background: inherit;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&:before,
				&:after {
					content: '';
					position: absolute;
					left: $nav-link-padding-x * -1;
					right: $nav-link-padding-x * -1;
					height: rem(2px);
					top: 50%;
					margin-top: rem(-1px);
					background-color: $gray-700;
					
					@include transition(all .2s linear);
				}
				&:after {
					width: 0%;
					background: $white;
				}
				&.disabled {
					color: $gray-600;
				}
				&.active,
				&.completed {
					background: $white;
					color: $gray-900;
					
					&:after {
						width: calc(100% + #{$nav-link-padding-x * 2});
					}
				}
				&.active {
					@include box-shadow(0 0 0 4px rgba($white, .2));
				}
			}
			&:first-child {
				& .nav-link {
					&:before,
					&:after {
						@if $enable-rtl {
							right: 50%;
						} @else {
							left: 50%;
						}
					}
				}
			}
			&:last-child {
				& .nav-link {
					&:before {
						@if $enable-rtl {
							left: 50%;
						} @else {
							right: 50%;
						}
					}
					&.active,
					&.completed {
						&:before {
							background: $white;
						}
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&.nav-wizards-3 {
		@include flex-wrap(nowrap);
		
		& .nav-item {
			padding: 0;
			
			& .nav-link {
				position: relative;
				color: $gray-700;
				
				& .nav-dot {
					width: rem(16px);
					height: rem(16px);
					margin: 0 auto;
					font-weight: 500;
					border: 3px solid $gray-700;
					position: relative;
					z-index: 10;
					
					@include border-radius(10px);
					@include display-flex();
					@include flex-align(center);
					@include flex-justify-content(center);
					@include transition(all .2s linear);
				}
				& .nav-title {
					margin-top: $spacer / 2;
					font-weight: $font-weight-bold;
					text-align: center;
					font-size: $font-size-lg;
				}
				& .nav-text {
					text-align: center;
					font-size: $font-size-base;
					color: $gray-600;
					font-weight: $font-weight-bold;
				}
				&.active,
				&.completed {
					color: $white;
					
					& .nav-dot {
						border-color: $white;
						color: $white;
					}
					&:before,
					&:after {
						background: $white;
					}
					&:after {
						width: calc(50% - 8px);
					}
				}
				&.active {
					& .nav-dot {
						@include box-shadow(0 0 0 5px rgba($white, .2));
					}
				}
				&.disabled {
					color: $gray-600;
					
					& .nav-text {
						color: inherit;
					}
				}
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: $nav-link-padding-y + rem-default(6px);
					height: rem(3px);
					background: $gray-700;
					
					@if $enable-rtl {
						right: 0;
						left: calc(50% + 8px);
					} @else {
						left: 0;
						right: calc(50% + 8px);
					}
				}
				&:after {
					@if $enable-rtl {
						left: 0;
						right: calc(50% + 8px);
					} @else {
						left: calc(50% + 8px);
						right: 0;
					}
					
					@include transition(all .2s linear);
				}
			}
			&:first-child {
				& .nav-link {
					&:before,
					&:after {
						@if $enable-rtl {
							right: calc(50% + 8px);
						} @else {
							left: calc(50% + 8px);
						}
					}
				}
			}
			&:last-child {
				& .nav-link {
					&:before {
						@if $enable-rtl {
							left: calc(50% + 8px);
						} @else {
							right: calc(50% + 8px);
						}
					}
					&.active,
					&.completed {
						&:before {
							background: $white;
						}
					}
					&:after {
						display: none;
					}
				}
			}
		}
	}
}