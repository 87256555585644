.hljs-wrapper {
	background: rgba($gray-900, .25);
	position: relative;
	
	@include border-radius($border-radius);
	
	.modal & {
		background: $gray-800;
	}
	
	& pre {
		border: none;
		padding: 0;
		background: none;
		margin: 0;
		
		@include border-radius(0);
		
		& .hljs,
		&.hljs {
			padding: rem(15px);
			display: block;
			overflow-x: auto;
			border: none;
			font-size: rem(12px);
			background: none;
			color: $gray-300;
			
			@include border-radius(0);
			
			& .hljs-tag,
			& .hljs-section,
			& .hljs-name,
			& .hljs-selector-tag,
			& .hljs-deletion,
			& .hljs-subst {
				color: tint-color($blue, 50%);
			}
			& .hljs-attr,
			& .hljs-variable,
			& .hljs-template-variable,
			& .hljs-type,
			& .hljs-selector-class,
			& .hljs-selector-attr,
			& .hljs-selector-pseudo,
			& .hljs-number {
				color: tint-color($blue, 50%);
			}
			& .hljs-string,
			& .hljs-regexp,
			& .hljs-addition,
			& .hljs-attribute,
			& .hljs-meta-string {
				color: tint-color($red, 50%);
			}
			& .hljs-comment {
				color: tint-color($dark, 50%);
			}
		}
	}
	& .btn-clipboard {
		position: absolute;
		right: rem(10px);
		top: rem(10px);
		
		@if $enable-rtl {
			right: auto;
			left: rem(10px);
		}
	}
}
.panel-body + .hljs-wrapper {
	@include border-radius(0 0 $border-radius $border-radius);
}
.modal {
	& .hljs-wrapper {
		margin-top: rem(-1px);
	
		@include border-radius(0);
		
		& + .modal-footer {
			border-top: none;
		}
	}
}