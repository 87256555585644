/* Sidebar.css */
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s ease-in-out;
    padding-top: 10px;
}

.sidebar.show {
    width: 320px;
}

.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 18px;
    color: #818181;
    display: block;
    
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidebar a {
        font-size: 16px;
    }

    .sidebar .closebtn {
        font-size: 30px;
        margin-left: 15px;
    }
}
